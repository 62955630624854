// colors helpers
.text-primary {
    color: var(--color-primary) !important;
}

.text-success {
    color: var(--color-success) !important;
}

.text-warning {
    color: var(--color-warning) !important;
}

.text-yellow {
    color: #e3ad04 !important;
}

.text-danger {
    color: var(--color-danger) !important;
}

.text-info {
    color: var(--color-info) !important;
}

.text-white {
    color: var(--color-white) !important;
}

.text-black {
    color: var(--color-black) !important;
}

.text-muted,
.text-muted a {
    color: var(--color-grey-400) !important;
}



// others
.position-relative {
    position: relative !important;
}

.nowrap {
    white-space: nowrap !important;
}