.section {
    padding: ($spacer * 3.5) 0;
    transition: filter 0.2s;

    @include media-breakpoint-down(xl) {
        padding: ($spacer * 2.25) 0;
    }

    @include media-breakpoint-down(lg) {
        padding: ($spacer * 1.875) 0;
    }

    &.section--text {
        padding: ($spacer * 4) 0;

        @include media-breakpoint-down(xl) {
            padding: ($spacer * 3.25) 0;
        }

        @include media-breakpoint-down(lg) {
            padding: ($spacer * 2.25) 0;
        }
    }

    &.section--page {
        padding: ($spacer * 2.875) 0;

        @include media-breakpoint-down(xl) {
            padding: ($spacer * 2.25) 0;
        }

        @include media-breakpoint-down(lg) {
            padding: ($spacer * 1.875) 0;
        }
    }

    &.section--banner {
        padding: 0;

        @include media-breakpoint-down(xl) {
            margin-bottom: $spacer * 2.25;
        }

        @include media-breakpoint-down(lg) {
            margin-bottom: $spacer * 1.875;
        }
    }

    &.section--hero {
        padding: 0 0 ($spacer * 4) 0;

        @include media-breakpoint-down(xl) {
            padding: 0 0 ($spacer * 3.25) 0;
        }

        @include media-breakpoint-down(lg) {
            padding: 0 0 ($spacer * 2.25) 0;
        }
    }

    &.section--bubble {
        margin-bottom: rem(-32px);
        overflow: hidden;
        position: relative;
    }

    &.section--error {
        margin-bottom: rem(-32px);
        padding: ($spacer * 6) 0;
        overflow: hidden;
        position: relative;

        @include media-breakpoint-down(lg) {
            padding: ($spacer * 4) 0;
        }

        @include media-breakpoint-down(md) {
            padding: ($spacer * 2) 0;
        }

        &::before {
            content: "";
            display: block;
            width: 300px;
            height: 300px;
            position: absolute;
            bottom: -60px;
            right: 10%;
            z-index: 0;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: cover;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='300' height='300' viewBox='0 0 24 24' fill='none' stroke='%23eeeeee' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'%3E%3C/path%3E%3Cline x1='12' y1='9' x2='12' y2='13'%3E%3C/line%3E%3Cline x1='12' y1='17' x2='12.01' y2='17'%3E%3C/line%3E%3C/svg%3E");

            @include media-breakpoint-down(md) {
                width: 170px;
                height: 170px;
                bottom: -30px;
            }
        }
    }
}




// bubble
.section__bubble {
    color: var(--color-black);
    position: absolute;
    top: 120px;
    right: -380px;
    z-index: -1;
    opacity: 0.04;

    @include media-breakpoint-down(xl) {
        transform: scale(0.7);
    }

    @include media-breakpoint-down(lg) {
        transform: scale(0.5);
        top: 0;
    }

    @include media-breakpoint-down(md) {
        transform: scale(0.35);
        top: -20px;
        right: -450px;
    }
}