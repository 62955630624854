// headings
%heading {
    margin-top: 0;
    line-height: $headings-line-height;
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 700;
    color: var(--color-black);

    @include media-breakpoint-down(md) {
        line-height: $headings-line-height * 0.875;
    }

    a {
        color: var(--color-black);
        text-decoration: none;
        transition: color 0.2s;

        &:hover,
        &:focus {
            color: var(--color-grey-800);
            text-decoration: none;
        }
    }
}

.display {
    @extend %heading;
    font-size: rem(46px);
    margin-bottom: $spacer * 2.5;

    @include media-breakpoint-down(lg) {
        font-size: rem(42px);
        margin-bottom: $spacer * 2;
    }

    @include media-breakpoint-down(md) {
        font-size: rem(38px);
    }

    @include media-breakpoint-down(sm) {
        font-size: rem(36px);
    }
}

h1,
.h1 {
    @extend %heading;
    font-size: rem(36px);
    margin-bottom: $spacer * 2.5;

    @include media-breakpoint-down(lg) {
        font-size: rem(34px);
    }

    @include media-breakpoint-down(md) {
        font-size: rem(34px);
    }
}

h2,
.h2 {
    @extend %heading;
    font-size: rem(30px);
    margin-bottom: $spacer * 1.5;

    @include media-breakpoint-down(md) {
        font-size: rem(28px);
    }
}

h3,
.h3 {
    @extend %heading;
    font-size: rem(24px);
    margin-bottom: $spacer * 1.25;

    @include media-breakpoint-down(md) {
        font-size: rem(22px);
    }
}

h4,
.h4 {
    @extend %heading;
    font-size: rem(20px);
    margin-bottom: $spacer * 1.25;

    @include media-breakpoint-down(md) {
        font-size: rem(18px);
    }
}

h5,
.h5 {
    @extend %heading;
    font-size: rem(18px);
    margin-bottom: $spacer;

    @include media-breakpoint-down(md) {
        font-size: rem(17px);
    }
}

h6,
.h6 {
    @extend %heading;
    text-transform: uppercase;
    font-size: rem(16px);
    margin-bottom: $spacer;

    @include media-breakpoint-down(md) {
        font-size: rem(15px);
    }
}


// text block
p {
    margin-top: 0;
    margin-bottom: $spacer * 1.5;
}

.lead {
    font-family: $headings-font-family;
    font-weight: 500;
    font-size: rem(20px);
    margin-bottom: $spacer * 2.5;

    @include media-breakpoint-down(md) {
        font-size: rem(18px);
        margin-bottom: $spacer * 1.5;
    }

    a {
        text-decoration-thickness: 1px;
        text-underline-offset: 2px;
    }
}

ul {
    margin: 0 0 ($spacer * 1.5) 0;
    padding: 0;

    li {
        list-style: none;
        margin: 0 0 $spacer 0;
        padding: 0 0 0 ($spacer * 1.875);
        position: relative;

        &::before {
            content: '';
            border: 2px solid var(--color-primary);
            border-radius: 50%;
            background-color: transparent;
            width: 10px;
            height: 10px;
            display: block;
            top: 7px;
            left: 0;
            position: absolute;
        }
    }

    &.list--unstyled > li {
        margin: 0;
        padding-left: 0;

        &::before {
            display: none;
        }
    }
}

ol {
    margin: 0 0 ($spacer * 1.5) 0;
    padding: 0 0 0 $spacer;

    li {
        margin: 0 0 $spacer 0;
        padding: 0 0 0 ($spacer * 1.5);
        list-style-type: decimal;
    }
}

dl {
    margin: 0 0 ($spacer * 1.25) 0;

    dt {
        color: var(--color-black);
        font-weight: 700;
        font-size: rem(17px);
        margin-right: $spacer * 0.675;

        &.dl--line {
            float: left;
            clear: left;
        }
    }

    dd {
        color: var(--color-black);
        font-size: rem(17px);
        margin: 0 0 ($spacer * 0.675) 0;

        &.dl--line {
            clear: right;
        }
    }
}


// text inline
a {
    color: var(--color-primary);
    @include underline;

    &:hover,
    &:focus {
        color: var(--color-primary);
        text-decoration: none;
    }
}

em,
.text-italic {
    font-style: italic;
}

b,
strong,
.text-bold {
    font-weight: 700;
}

.text-medium {
    font-weight: 500;
}

del,
.text-strike {
    text-decoration: line-through;
}

u,
.text-underline {
    @include underline;
}

big,
.text-big {
    font-size: 155%;

    @include media-breakpoint-down(sm) {
        font-size: 145%;
    }
}

small,
.text-small {
    font-size: 75%;

    @include media-breakpoint-down(sm) {
        font-size: 80%;
    }
}

.text-upper {
    text-transform: uppercase !important;
}

.text-default {
    font-family: var(--body-font-family) !important;
}



// definition offsets
.def {
    padding-left: $spacer * 2.5;
    position: relative;
}

.def__num {
    color: var(--color-primary);
    left: 0;
    top: 0;
    position: absolute;
}

.def--offset {
    padding-left: $spacer * 5.25;

    .def__num {
        left: $spacer * 2.5;
    }
}



// offsets
.section {
    /*h1 + * {
    margin-top: 12px;
  }

  h2 + * {
    margin-top: 12px;
  }

  h3 + * {
    margin-top: 12px;
  }

  h4 + * {
    margin-top: 8px;
  }

  h5 + * {
    margin-top: 4px;
  }

  h6 + * {
    margin-top: 4px;
  }*/

    p + h2,
    ul + h2,
    ol + h2,
    pre + h2,
    table + h2,
    dl + h2 {
        margin-top: $spacer * 2;
    }

    p + h3,
    ul + h3,
    ol + h3,
    pre + h3,
    table + h3,
    dl + h3 {
        margin-top: $spacer * 2;
    }

    p + h4,
    ul + h4,
    ol + h4,
    pre + h4,
    table + h4,
    dl + h4 {
        margin-top: $spacer * 2;
    }

    p + h5,
    ul + h5,
    ol + h5,
    pre + h5,
    table + h5,
    dl + h5 {
        margin-top: $spacer * 2;
    }

    p + h6,
    ul + h6,
    ol + h6,
    pre + h6,
    table + h6,
    dl + h6 {
        margin-top: $spacer * 2;
    }

    /*p + *, ul + *, ol + *, pre + *, table + *, dl + * {
    margin-top: 20px;
  }*/

    h1 + h2 {
        margin-top: $spacer * 2;
    }

    h2 + h3 {
        margin-top: $spacer * 2;
    }

    h3 + h4 {
        margin-top: $spacer * 2;
    }

    h4 + h5 {
        margin-top: $spacer * 2;
    }

    h5 + h6 {
        margin-top: $spacer * 2;
    }

    /*figure + h2,
  figure + h3,
  figure + h4,
  figure + h5,
  figure + h6,
  figure + * {
    margin-top: 40px;
  }*/

    /*div + p,
  div + table,
  div + ul,
  div + ol,
  div + dl,
  div + h1,
  div + h2,
  div + h3,
  div + h4,
  div + h5,
  div + h6,
  div + figure {
    margin-top: $spacer * 2;
  }

  p + figure, ul + figure, ol + figure, pre + figure, table + figure, dl + figure,  h1 + figure, h2 + figure, h3 + figure, h4 + figure, h5 + figure, h6 + figure {
    margin-top: 40px;
  }

  p + div, ul + div, ol + div, pre + div, table + div, dl + div, h1 + div, h2 + div, h3 + div, h4 + div, h5 + div, h6 + div {
    margin-top: rem(48px);
  }*/
}