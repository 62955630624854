// banner
.banner {
    //background-color: var(--color-grey-100);
    //aspect-ratio: 915/289;
    //@include boxshadow;
    //@include watermark;
    display: block;
    position: relative;
}

.banner__img {
    width: 100%;
    height: auto;
    aspect-ratio: 915/289;
}