// boxes
.box {
    border: none;
    background-color: var(--color-grey-100);
    //margin: 0 0 ($spacer * 3.75) 0;
    //padding: $spacer * 2.75;
    margin: 0 0 ($spacer * 3.75) 0;
    padding: $spacer * 3.375;
    display: block;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(xxl) {
        margin-bottom: $spacer * 3.25;
        padding: ($spacer * 2.25) ($spacer * 2);
    }

    @include media-breakpoint-down(md) {
        padding: ($spacer * 2.25) ($spacer * 1.625);
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: $spacer * 2.25;
        padding: ($spacer * 2) ($spacer * 1.25);
    }

    &.box--visible {
        overflow: visible;
    }

    &.box--sticky {
        position: sticky;
        top: 0;
    }

    &.box--video {
        background: none;
        padding: 0;

        @include media-breakpoint-down(xl) {
            margin-top: $spacer * 3.5;
        }
    }

    &.box--narrow {
        padding: $spacer * 2.25;

        @include media-breakpoint-down(lg) {
            margin-bottom: $spacer * 2;
            padding: $spacer * 1.625;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: $spacer * 1.625;
        }
    }

    &.box--outline {
        background-color: var(--color-white);
        border: 1px solid var(--border-color);
    }

    &.box--control {
        background-color: var(--color-grey-100);
        margin: 0;
        padding: ($spacer * 1.75) ($spacer * 2.1875);

        @include media-breakpoint-down(xl) {
            padding: ($spacer * 1.25) ($spacer * 1.875);
        }
    }

    &.box--catalog {
        padding: rem(24px) rem(30px);

        @include media-breakpoint-down(md) {
            text-align: center;

            .link {
                margin-top: $spacer * 2;
            }
        }

        .catalog-img-bottom {
            margin-bottom: rem(-24px);

            @include media-breakpoint-between(xl, xxl) {
                display: none;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &.box--line {
        background-color: transparent;
        border-top: 1px solid var(--border-color);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    &.box--line-down {
        background-color: transparent;
        border-bottom: 1px solid var(--border-color);
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

    &.box--arrow {
        background-color: var(--color-white);
        border: 2px solid var(--border-color);
        padding: ($spacer * 1.375) ($spacer * 2);
        margin-bottom: $spacer * 5;
        overflow: visible;

        @include media-breakpoint-down(md) {
            padding: $spacer * 1.125;
            margin-bottom: $spacer * 3.5;
        }

        &::before,
        &::after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
        }

        &::before {
            border-width: 0 11.5px 11px 11.5px;
            border-color: transparent transparent var(--border-color) transparent;
            left: 30px;
            bottom: 100%;
        }

        &::after {
            border-width: 0 9px 8px 9px;
            border-color: transparent transparent var(--color-white) transparent;
            left: 32px;
            bottom: 100%;
        }
    }
}

.wrap__bubble {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 200px;
    overflow: hidden;
    z-index: -1;
}

.box__bubble {
    color: var(--color-black);
    position: absolute;
    top: -20px;
    right: 40px;
    z-index: 0;
    opacity: 0.1;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &.bubble--offset {
        margin-right: 120px;
    }
}