.subheader {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: $spacer * 3.875;
    padding-bottom: $spacer * 3.375;
    position: relative;

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer * 2.5;
        padding-bottom: $spacer * 2.375;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: $spacer * 2.5;
        padding-bottom: $spacer * 2.125;
    }

    .heading-row,
    .heading-row__filter.filter--alone {
        margin-bottom: 0;
        position: relative;
        z-index: 10;
    }

    @include media-breakpoint-down(md) {
        .nav__dropdown {
            right: auto;
            left: 4px;

            &::after {
                right: auto;
                left: 18px;
            }
        }
    }
}

.subheader__heading {
    @extend .display;
    margin-bottom: $spacer * 1.5;
    position: relative;
    z-index: 10;
}

.subheader__bubble {
    width: 400px;
    height: 250px;
    position: absolute;
    bottom: 0;
    right: 140px;
    z-index: 0;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
        width: 230px;
        height: 170px;
    }

    @include media-breakpoint-down(sm) {
        right: 0;
    }

    svg {
        color: var(--color-black);
        opacity: 0.05;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: -90px;
        right: 0;

        @include media-breakpoint-down(lg) {
            bottom: -60px;
        }

        @include media-breakpoint-down(sm) {
            transform: scale(0.7);
        }
    }
}



// heading row (h2 / more / filter / search)
.heading-row {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(38px);

    @include media-breakpoint-down(xl) {
        margin-bottom: rem(30px);
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: rem(28px);
    }

    &.row--wrap {
        white-space: normal;
        flex-wrap: wrap;
    }

    .priority-nav-has-dropdown {
        .nav__dropdown-wrapper {
            display: inline-block;
            vertical-align: bottom;
        }

        .nav__dropdown-toggle {
            @extend .button;
            @extend .button--outline;
            @extend .button--small;
            border-color: var(--color-grey-200) !important;
            background-color: var(--color-white) !important;
            background-image: url("../img/icon/three-dots.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px;
            vertical-align: middle;
            margin: 0 0 0 rem(9px);
            width: 42px;
            height: 32px;

            &:hover,
            &:focus {
                border-color: var(--color-black) !important;
            }
        }
    }
}

.heading-row__title {
    margin: 0 rem(40px) 0 0;

    @include media-breakpoint-down(lg) {
        margin-right: rem(30px);
    }

    @include media-breakpoint-down(sm) {
        margin-right: rem(24px);
    }
}

.heading-row__more {
    margin: 0;

    @include media-breakpoint-down(sm) {
        span {
            display: none;
        }

        &::after {
            content: "Více";
            @include underline;
        }
    }
}

.heading-row__goto,
.heading-row__end {
    @include media-breakpoint-down(lg) {
        margin-top: $spacer;
        margin-bottom: $spacer;
    }

    @include media-breakpoint-down(sm) {
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 0;

        .heading-row__search {
            margin: 0;
        }

        .form__control.heading-row__search-control {
            width: 250px;
        }
    }
}

.heading-row__filter {
    white-space: normal !important;
    text-align: end;
    width: 100%;
    margin: 0 0 0 rem(40px);

    @include media-breakpoint-down(lg) {
        margin-left: rem(30px);
    }

    @include media-breakpoint-down(sm) {
        margin-left: rem(24px);
    }

    &.filter--alone {
        text-align: start;
        margin-left: 0;
    }
}

.heading-row__search {
    margin-top: -6px;
    margin-bottom: -6px;
    margin-left: $spacer;
    position: relative;
}

.form__control.heading-row__search-control {
    width: 270px;
    max-width: 100%;
    padding-left: 50px;

    @include media-breakpoint-down(md) {
        width: 190px;
    }

    @include media-breakpoint-down(sm) {
        padding-right: 0.5rem;
        width: 125px;
    }

    &::placeholder {
        color: var(--color-black);
        opacity: 1;
    }
}

.heading-row__search-button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 13px;
    left: 18px;
    transition: color 0.2s;
    cursor: pointer;

    .icon {
        width: 18px;
        height: 18px;
    }

    &:hover,
    &:focus {
        color: var(--color-primary);
    }
}