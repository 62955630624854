@use 'sass:math';

// fce for converting a px based font-size to rem
@function rem($size) {
    @return math.div($size, $font-size-base-px) * 1rem;
}

// undreline link headings
@mixin underline {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
}


// cover box shadow
@mixin boxshadow {
    box-shadow: 0 7px 20px -9px rgba($black-color, 0.75);
}

// watermark
@mixin watermark {
    &::before {
        content: "";
        background-image: url("../img/logotyp-crew.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 40px;
        height: 52px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.1;
        z-index: 0;
    }
}

// avatar rounded
@mixin avatar($size) {
    width: $size;
    height: $size;
    border-radius: 50%;
    overflow: hidden;

    & > img,
    .avatar__img {
        margin: 0;
        width: 100%;
        height: 100%;
        ;
        display: block;
        object-fit: cover;
    }
}

// text line crop
@mixin linecrop($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}