// comment
.comment {
    margin-bottom: $spacer * 2.375;
    display: flex;
    position: relative;

    @include media-breakpoint-down(sm) {
        margin-bottom: $spacer * 1.375;
    }

    @media (max-width: 400px) {
        margin-bottom: $spacer * 1.875;
    }
}

.comment--answer {
    padding-left: 80px;
    position: relative;

    @include media-breakpoint-down(sm) {
        background-color: var(--color-grey-110);
        margin-top: $spacer * -1.875;
        padding-top: $spacer * 1.375;
        padding-left: $spacer;
        padding-right: $spacer;
    }

    &::before {
        content: "";
        background: url("../img/icon/arrow-return-right.svg") no-repeat center;
        background-size: 20px;
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 14px;
        left: 14px;
        z-index: 10;
        opacity: 0.35;

        @include media-breakpoint-down(sm) {
            border-radius: 50%;
            background-color: var(--color-white);
            background-size: 14px;
            top: $spacer * 0.875;
            left: $spacer * 0.875;
            opacity: 1;
            @include boxshadow;
        }
    }
}

.answer__user a {
    color: var(--color-black);
    text-decoration: none;
    transition: color 0.2s;

    &:hover,
    &:focus {
        color: var(--color-grey-500);
    }
}

.answer__icon {
    line-height: 1;
    margin: -1px ($spacer * 0.675);
    width: 17px;
    min-width: 17px;
    height: 17px;
}

.comment__side {
    margin-right: rem(30px);
    flex: 0 0 auto;
    width: 50px;
    max-width: 100%;

    @include media-breakpoint-down(sm) {
        margin-right: rem(20px);
        width: 40px;
    }
}

.comment__image {
    @include avatar(50px);
    @include boxshadow;

    @include media-breakpoint-down(sm) {
        width: 40px;
        height: 40px;
    }
}

.comment__container {
    border-bottom: 1px solid var(--border-color);
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;
    padding-bottom: $spacer * 2.375;

    @include media-breakpoint-down(sm) {
        padding-bottom: $spacer * 1.375;
    }

    .comment--last & {
        border-bottom: 0;
    }
}

.comment__heading {
    font-size: rem(18px);
    margin-bottom: rem(2px);
    padding-right: 50px;

    a {
        color: var(--color-primary);
        @include underline;
        text-underline-offset: 2px;

        &:hover,
        &:focus {
            color: var(--color-primary);
            text-decoration: none;
        }

        &.comment__num {
            margin-left: $spacer * 0.75;
            font-weight: 400;
            font-size: 85%;
            color: var(--color-grey-300);
            text-decoration: none;

            &:hover,
            &:focus {
                color: var(--color-black);
                text-decoration: none;
            }
        }
    }
}

.comment__meta {
    margin: 0 0 rem(12px) 0;
    padding: 0 50px 0 0;
}

.comment__text {
    margin-bottom: 0;
}

.comment__reply {
    position: absolute;
    top: 0;
    right: 0;

    span {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    svg {
        @include media-breakpoint-up(sm) {
            display: none;
        }
    }
}



// person medailon
.person {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: $spacer * 1.675;
}

.person__avatar {
    margin-right: $spacer;
    @include avatar(40px);
    @include boxshadow;

    @include media-breakpoint-down(sm) {
        width: 30px;
        height: 30px;
    }
}

.person__name {
    font-size: rem(17px);
    line-height: 1;
    margin-bottom: $spacer * 0.25;
    display: block;
}

.person__email {
    color: var(--color-grey-600);
    font-size: rem(15px);
    line-height: 1;
    display: block;
}



// add comment form
.addcomment {
    background-color: var(--color-white);
    padding: $spacer;
    position: relative;
    box-shadow: 0 7px 20px -9px rgba(0, 0, 0, 0.2);
}

.addcomment__label {
    font-weight: 700;
    margin: 0 ($spacer * 0.25) ($spacer * 0.5) ($spacer * 0.25);
    padding-right: $spacer * 1.25;
    width: 100%;
    position: relative;
    display: none;
}

.addcomment__label-reset {
    color: var(--color-grey-700);
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    top: 0;
    right: $spacer * 0.25;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: none;
        color: var(--color-primary);
    }
}

.addcomment__textarea {
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    font-weight: var(--body-font-weight);
    color: var(--color-black);
    background-color: var(--color-white);
    border: none;
    padding: 0 ($spacer * 0.25);
    width: 100%;
    height: 180px;

    &:focus {
        outline: none;
    }
}

.addcomment__control {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: $spacer * 0.25;
}

.button.button--smile {
    color: var(--color-grey-500);
    margin-right: $spacer * 0.5;
    padding-left: $spacer * 0.75;
    padding-right: $spacer * 0.75;

    &:hover,
    &:focus {
        background-color: var(--color-grey-100);
        border-color: var(--color-grey-100);
        color: var(--color-grey-700);
    }
}

.addcomment__emoticones {
    background-color: var(--color-white);
    border-top: 1px solid var(--color-grey-100);
    padding: $spacer;
}

.button.button--emoticon {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 6px 5px;

    &:hover,
    &:focus {
        background-color: var(--color-grey-100);
    }
}