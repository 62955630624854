// card
.card {
    margin-bottom: $spacer * 3;
    margin-left: auto;
    margin-right: auto;
    width: 173px;
    max-width: 100%;

    @include media-breakpoint-down(md) {
        margin-bottom: $spacer * 2;
    }
}

.card__image {
    //background-color: var(--color-grey-100);
    //@include watermark;
    margin-bottom: $spacer;
    display: block;
    position: relative;
    aspect-ratio: 171/243;
    min-height: 90px;

    & > img {
        @include boxshadow;
        margin: 0;
        display: block;
        width: auto;
        height: auto;
        max-width: 100% !important;
        max-height: 100%;
        position: relative;
        z-index: 1;
        transition: box-shadow 0.3s, transform 0.2s;

        .card:hover & {
            transform: translate3d(0, -4px, 0);
        }
    }

    .tags {
        text-align: start;
        left: auto;
        left: 0;
        bottom: 45px;

        .tag {
            font-size: rem(11px);
            margin-top: $spacer * 0.5;
            float: left;
            clear: left;
        }
    }
}

/*.box:not(.box--line):not(.box--outline) .card__image {
  background-color: var(--color-white);
}*/

.card__heading {
    @extend .h5;
    font-size: rem(17px);
    margin-bottom: $spacer * 0.875;

    a {
        color: var(--color-black);
        @include underline;
        text-underline-offset: 2px;

        &:hover,
        &:focus,
        .card:hover & {
            color: var(--color-black);
            text-decoration: none;
        }
    }
}

.card__meta {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-right: 45px;

    @media (max-width: 400px) {
        padding-right: 0;
        display: block;
    }

    .badge {
        margin-right: $spacer * 0.5;
        margin-bottom: $spacer * 0.125;
        flex-wrap: nowrap;
        flex: 0 0 auto;
        max-width: 100%;
        width: auto;

        @include media-breakpoint-down(md) {
            margin-bottom: $spacer * 0.5;
        }

        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
        }
    }

    .link {
        flex-wrap: nowrap;
        flex: 0 0 auto;
        width: auto;
        display: flex;
        width: 35px;
        margin-right: -40px;

        @media (max-width: 400px) {
            margin-right: 0;
        }
    }
}

.card__buy {
    margin-top: $spacer * 0.875;

    @include media-breakpoint-down(md) {
        margin-top: $spacer * 0.75;
    }
}



// card grid
.col--card {
    flex: 0 0 auto;

    &.col--card-7 {
        width: calc(100% / 7);

        @include media-breakpoint-down(xxl) {
            width: calc(100% / 6);
        }

        @include media-breakpoint-down(xl) {
            width: calc(100% / 5);
        }

        @include media-breakpoint-down(lg) {
            width: calc(100% / 4);
        }

        @include media-breakpoint-down(md) {
            width: calc(100% / 3);
        }

        @media (max-width: 480px) {
            width: calc(100% / 2);
        }
    }

    &.col--card-4 {
        width: calc(100% / 4);

        @include media-breakpoint-down(xl) {
            width: calc(100% / 4);
        }

        @include media-breakpoint-down(md) {
            width: calc(100% / 3);
        }

        @media (max-width: 480px) {
            width: calc(100% / 2);
        }
    }

    .card {
        @include media-breakpoint-up(lg) {
            //margin-bottom: rem(58px);
        }

        @media (min-width: 481px) {
            width: 100%;
        }
    }
}


// card flex
.card.card--flex {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row--card-flex {
    > div[class^="col"] {
        margin-bottom: $spacer * 3.5;

        @include media-breakpoint-down(md) {
            margin-bottom: $spacer * 2;
        }

        > .card {
            @extend .card--flex;
        }
    }
}