table {
    border: none;
    width: 100%;
    margin: 0 0 ($spacer * 2) 0;
    padding: 0;

    @include media-breakpoint-down(sm) {
        margin: ($spacer * 2) 0;
    }

    th {
        font-size: rem(17px);
        font-weight: 700;
        border: none;
        border-bottom: 1px solid var(--border-color);
        //background-color: var(--color-grey-100);
        color: var(--color-black);
        line-height: 1;
        text-align: left;
        text-transform: none;
        padding: ($spacer * 1.375) ($spacer * 1.6125);
    }

    td {
        color: var(--color-black);
        text-align: left;
        border: none;
        border-bottom: 1px solid var(--border-color);
        //background-color: var(--color-white);
        padding: ($spacer * 1.125) ($spacer * 1.6125);
    }

    td:nth-of-type(1),
    th:nth-of-type(1) {
        padding-left: 0;
    }

    tr:nth-last-of-type(1) {
        td {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }



    &.table--responsive {
        @include media-breakpoint-down(sm) {
            tr {
                //background-color: var(--color-white);
                border-bottom: 1px solid var(--border-color);
                margin: 0;
                padding: 0.75rem 0.5rem;
                display: block;

                &:nth-last-of-type(1) {
                    border-bottom: 0;
                }
            }

            th,
            thead {
                display: none;
            }

            td {
                border: none;
                background: none;
                text-align: left !important;
                display: block;
                padding: 0.125rem 0.675rem 0.125rem 6rem !important;
                position: relative;

                &::before {
                    content: attr(data-th);
                    color: var(--color-grey-400);
                    font-weight: 400;
                    font-size: rem(12px);
                    line-height: 1;
                    text-align: right;
                    text-transform: uppercase;
                    width: 4.7rem;
                    display: block;
                    position: absolute;
                    top: 8px;
                    left: 0;
                }
            }
        }
    }
}