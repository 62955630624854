.regform {
    margin: ($spacer * 3.5) 0;
    width: 400px;
    max-width: 100%;

    @include media-breakpoint-down(md) {
        width: 350px;
    }
}



// avatar
.regform__avatar {
    margin-top: $spacer * 0.5;
    display: block;
    float: right;
    clear: both;
    position: relative;

    @include media-breakpoint-down(md) {
        margin-bottom: $spacer * 2.5;
    }

    @include media-breakpoint-down(sm) {
        float: left;
    }

    #file-input {
        display: none;
    }
}

.regform__avatar-image {
    @include avatar(180px);
    @include watermark;
    @include boxshadow;
    background-color: var(--color-grey-100);
    aspect-ratio: 1/1;

    @include media-breakpoint-down(md) {
        width: 120px;
        height: 120px;
    }

    img {
        margin: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
        position: relative;
        z-index: 1;
    }
}

.button.button--edit {
    width: 46px;
    height: 46px;
    padding: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
}