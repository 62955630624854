:root {
    // colors
    --color-primary: #{$primary-color};
    --color-primary-dark: #{$primary-dark-color};
    --color-primary-light: #{$primary-light-color};

    --color-success: #{$success-color};
    --color-success-dark: #{$success-dark-color};
    --color-success-light: #{$success-light-color};
    --color-danger: #{$danger-color};
    --color-warning: #{$warning-color};
    --color-info: #{$info-color};

    --color-white: #{$white-color};
    --color-black: #{$black-color};

    --color-grey-110: #{$grey-110-color};
    --color-grey-100: #{$grey-100-color};
    --color-grey-200: #{$grey-200-color};
    --color-grey-300: #{$grey-300-color};
    --color-grey-400: #{$grey-400-color};
    --color-grey-500: #{$grey-500-color};
    --color-grey-600: #{$grey-600-color};
    --color-grey-700: #{$grey-700-color};
    --color-grey-800: #{$grey-800-color};

    // typo
    --body-font-family: #{$font-family-base};
    --body-font-size: #{$font-size-base};
    --body-font-weight: #{$font-weight-base};
    --body-line-height: #{$line-height-base};

    @include media-breakpoint-down(md) {
        --body-line-height: #{$line-height-base * 0.9};
    }

    // body
    --body-color: #{$body-color};
    --body-bg: #{$body-bg};

    // layout
    --border-color: #{$border-color};
    --crop-height: #{$crop-height};
}