// badges
.badge {
    font-size: rem(14px);
    font-family: var(--body-font-family);
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    color: var(--color-white);
    background-color: var(--color-black);
    display: inline-flex;
    justify-content: start;
    align-items: center;
    padding: 0.25rem 0.4rem;

    &.badge--success,
    &.badge--green {
        background-color: var(--color-success);
    }

    &.badge--warning,
    &.badge--yellow {
        color: var(--color-black);
        background-color: var(--color-warning);
    }

    &.badge--danger,
    &.badge--red {
        background-color: var(--color-danger);
    }

    &.badge--buy {
        background-color: var(--color-primary);
    }

    &.badge--info,
    &.badge--blue {
        background-color: var(--color-info);
    }

    &.badge--grey {
        color: var(--color-white);
        background-color: var(--color-grey-400);
    }

    &.badge--white {
        color: var(--color-black);
        background-color: var(--color-white);
    }

    &.badge--rounded {
        border-radius: 50px;
    }

    &.badge--mini {
        font-size: rem(10px);
        font-weight: 400;
        padding: 0.1875rem 0.3125rem;
    }
}


// tags
.tags {
    text-align: start;
    position: absolute;
    bottom: 45px;
    left: 0;
    z-index: 2;

    .tag {
        font-weight: 500;
        margin-top: rem(10px);
        float: left;
        clear: left;
    }
}


// badge count
.badge__wrap {
    position: relative;
}

.badge__count {
    font-size: rem(12px);
    font-weight: 400;
    line-height: 1;
    color: var(--color-white);
    background-color: var(--color-primary);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    min-width: 18px;
    height: 18px;
    position: absolute;
    top: -4px;
    left: calc(100% + 6px);
    z-index: 10;

    &::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 2.5px 0 2.5px;
        border-color: var(--color-primary) transparent transparent transparent;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: rotate(45deg);
    }
}