// alerts
.alert {
    color: $white-color;
    background-color: $black-color;
    display: flex;
    align-items: center;
    margin-bottom: $spacer * 1.25;
    padding: 1.0625rem 3.875rem 1.0625rem 1.375rem;
    position: relative;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
    }

    a,
    a:hover {
        color: $white-color;
    }

    &.alert--noclose {
        padding-right: 1.375rem;
    }

    &.alert--success {
        background-color: $success-color;

        .alert__icon {
            color: $success-color;
        }
    }

    &.alert--danger {
        background-color: $danger-color;

        .alert__icon {
            color: $danger-color;
        }
    }

    &.alert--warning {
        background-color: $warning-color;

        .alert__icon {
            color: $warning-color;
        }
    }

    &.alert--info {
        background-color: $info-color;

        .alert__icon {
            color: $info-color;
        }
    }
}

.alert__icon {
    background-color: $white-color;
    border-radius: 50%;
    margin-right: 1.375rem;
    width: 36px;
    min-width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(sm) {
        margin-bottom: $spacer * 0.75;
        width: 30px;
        min-width: 30px;
        height: 30px;
    }
}

.alert__close {
    line-height: 1;
    color: $white-color;
    border: none;
    background: none;
    opacity: 0.6;
    cursor: pointer;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    right: 1.375rem;
    transform: translateY(-50%);
    position: absolute;
    opacity: 1;
    transition: opacity 0.2s;

    &:hover,
    &:focus {
        outline: none;
        opacity: 0.7;
    }
}



// alert comment
.alerts.alerts--comment {
    left: $spacer * 3;
    bottom: $spacer * 1;
    position: fixed;
    z-index: 999;

    @include media-breakpoint-down(sm) {
        left: $spacer * 1;
        right: $spacer * 1;
        bottom: 0;
    }

    .alert {
        background-color: transparent;
        margin: 0 0 ($spacer * 3) 0;
        padding: 0;
        display: flex;
        animation: jello 2s;
        transform-origin: center;

        @include media-breakpoint-down(sm) {
            display: inline-flex;
            margin-bottom: $spacer * 1.5;
        }

        &::before {
            content: "";
            width: 60px;
            height: 45px;
            mask: url('../img/icon/comment-foot.svg') no-repeat 100% 100%;
            mask-size: cover;
            -webkit-mask: url('../img/icon/comment-foot.svg') no-repeat 100% 100%;
            -webkit-mask-size: cover;
            display: block;
            top: calc(100% - $spacer);
            left: 0;
            position: absolute;
            z-index: 1;

            @include media-breakpoint-down(sm) {
                width: 50px;
                height: 35px;
            }
        }

        .alert__icon {
            border-radius: 50%;
            background-color: var(--color-grey-700);
            color: var(--color-white);
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 45px;
            top: 50%;
            left: $spacer * 1.875;
            position: absolute;
            transform: translateY(-50%);
            z-index: 10;

            @include media-breakpoint-down(sm) {
                width: 35px;
                height: 35px;
                left: $spacer * 1.6125;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .alert__close {
            color: var(--color-grey-700);
            background: var(--color-grey-200);
            border: none;
            border-radius: 50%;
            width: 26px;
            height: 26px;
            opacity: 1;
            top: 0;
            right: $spacer;
            transform: none;
            position: absolute;
            opacity: 1;
            transition: background-color 0.2s, color 0.2s;
            z-index: 10;

            &:hover,
            &:focus {
                background: var(--color-grey-700);
                color: var(--color-white);
                opacity: 1;
            }

            svg {
                width: 10px;
                height: 10px;
            }
        }

        .alert__text {
            color: var(--color-white);
            font-size: rem(18px);
            line-height: 1.625;
            padding: ($spacer * 1.875) ($spacer * 2.875) ($spacer * 2) ($spacer * 6);
            max-width: 430px;
            mask: url('../img/icon/comment.svg') no-repeat 100% 100%;
            mask-size: cover;
            -webkit-mask: url('../img/icon/comment.svg') no-repeat 100% 100%;
            -webkit-mask-size: cover;
            display: inline-block;
            position: relative;
            z-index: 5;

            @include media-breakpoint-down(sm) {
                font-size: rem(16px);
                line-height: 1.375;
                padding: ($spacer * 1.75) ($spacer * 2.875) ($spacer * 1.8125) ($spacer * 4.875);
            }
        }

        &.alert--success {
            filter: drop-shadow(0 10px 16px rgba($success-color, 0.35));

            &::before {
                background-color: var(--color-success);
            }

            .alert__text {
                background-color: var(--color-success);
            }
        }

        &.alert--danger {
            filter: drop-shadow(0 10px 16px rgba($danger-color, 0.35));

            &::before {
                background-color: var(--color-danger);
            }

            .alert__text {
                background-color: var(--color-danger);
            }
        }

        &.alert--warning {
            filter: drop-shadow(0 10px 16px rgba($warning-color, 0.35));

            &::before {
                background-color: var(--color-warning);
            }

            .alert__text {
                background-color: var(--color-warning);
            }
        }

        &.alert--info {
            filter: drop-shadow(0 10px 16px rgba($info-color, 0.35));

            &::before {
                background-color: var(--color-info);
            }

            .alert__text {
                background-color: var(--color-info);
            }
        }
    }



    // alert with illustration
    &.alert--illustration {
        .alert {
            margin-bottom: $spacer * 4;

            @include media-breakpoint-down(sm) {
                margin-bottom: $spacer * 2;
            }

            &::before {
                content: "";
                background-size: contain;
                background-color: transparent;
                background-position: center;
                background-repeat: no-repeat;
                width: 110px;
                height: 150px;
                mask: none !important;
                mask-size: none !important;
                -webkit-mask: none !important;
                -webkit-mask-size: none !important;
                display: block;
                top: -35px;
                left: -35px;
                position: absolute;
                z-index: 15;

                @include media-breakpoint-down(sm) {
                    width: 80px;
                    height: 110px;
                    top: -15px;
                    left: -10px;
                }
            }

            .alert__text {
                padding-left: $spacer * 8.5;
                max-width: 480px;

                @include media-breakpoint-down(sm) {
                    padding-left: $spacer * 7;
                }
            }

            .alert__icon {
                left: $spacer * 4.25;

                @include media-breakpoint-down(sm) {
                    left: $spacer * 3.75;
                }
            }

            &.alert--success {
                &::before {
                    background-image: url("../img/alert/alert-success.png");
                    background-image: image-set("../img/alert/alert-success.png" 1x,
                            "../img/alert/alert-success@2x.png" 1.25x,
                            "../img/alert/alert-success@2x.png" 2x);
                }
            }

            &.alert--danger {
                &::before {
                    background-image: url("../img/alert/alert-danger.png");
                    background-image: image-set("../img/alert/alert-danger.png" 1x,
                            "../img/alert/alert-danger@2x.png" 1.25x,
                            "../img/alert/alert-danger@2x.png" 2x);
                }
            }

            &.alert--warning {
                &::before {
                    background-image: url("../img/alert/alert-warning.png");
                    background-image: image-set("../img/alert/alert-warning.png" 1x,
                            "../img/alert/alert-warning@2x.png" 1.25x,
                            "../img/alert/alert-warning@2x.png" 2x);
                }
            }

            &.alert--info {
                &::before {
                    background-image: url("../img/alert/alert-info.png");
                    background-image: image-set("../img/alert/alert-info.png" 1x,
                            "../img/alert/alert-info@2x.png" 1.25x,
                            "../img/alert/alert-info@2x.png" 2x);
                }
            }
        }
    }
}



// animation
@keyframes jello {
    11.1% {
        transform: none
    }

    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }

    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg)
    }

    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }

    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }

    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg)
    }

    77.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg)
    }

    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
    }

    100% {
        transform: none
    }
}