.newsletter {
    margin-top: rem(32px);
    margin-bottom: -80px;
    position: relative;
    z-index: 10;
    overflow: hidden;
    transition: filter 0.2s;

    @include media-breakpoint-down(sm) {
        margin-bottom: -130px;
    }
}

.newsletter__box {
    background-color: var(--color-primary);
    background-image: url("../img/illustration/bg-newsletter.png");
    background-repeat: no-repeat;
    background-position: center top;
    padding: ($spacer * 2.8125) 0 ($spacer * 2.8125) ($spacer * 3.125);
    position: relative;

    @include boxshadow;

    @include media-breakpoint-down(lg) {
        background-position: right 20px top 0;
    }

    @include media-breakpoint-down(md) {
        background-position: right 10px top 0;
        background-size: 180px auto;
        padding: ($spacer * 2) 0 ($spacer * 2) ($spacer * 2.125);
    }

    @include media-breakpoint-down(sm) {
        padding: ($spacer * 1.75) 0 ($spacer * 1.75) ($spacer * 1.5);
    }

    &::after {
        content: "";
        background-color: var(--color-primary);
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 0;
    }
}

.newsletter__heading {
    @extend .h1;
    color: var(--color-white);
    margin: 0 0 ($spacer * 0.675) 0;

    @include media-breakpoint-down(lg) {
        margin-bottom: 0;
    }
}

.newsletter__text {
    margin: 0;
    color: var(--color-white);
    opacity: 0.8;

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.form-newsletter {
    margin-top: $spacer * 0.375;

    @include boxshadow;

    @include media-breakpoint-down(md) {
        margin-top: $spacer * 1.5;
    }
}

.newsletter__gdpr {
    font-size: rem(14px);
    color: var(--color-black);
    margin: ($spacer * 0.5) 0 0 0;

    a {
        color: var(--color-black);
    }
}