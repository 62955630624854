.header {
    background-color: var(--color-grey-110);
    position: relative;
    transition: filter 0.3s;
    z-index: 910;

    @include media-breakpoint-down(lg) {
        background: none;
    }

    .container {
        padding-top: rem(30px);
        padding-bottom: rem(30px);
        position: relative;

        @include media-breakpoint-down(lg) {
            padding-top: rem(30px);
            padding-bottom: rem(30px);

            &::before {
                content: "";
                width: calc(100% - 2.5rem);
                height: 1px;
                background-color: var(--border-color);
                display: block;
                position: absolute;
                left: 1.25rem;
                top: 100%;
            }
        }

        @include media-breakpoint-down(md) {
            padding-top: rem(25px);
            padding-bottom: rem(25px);
        }

        @media (max-width: 400px) {
            padding-top: rem(20px);
            padding-bottom: rem(20px);
        }
    }
}

.header__logo {
    text-decoration: none;
    //display: flex;
    align-items: center;
}

.header__logo-crew {
    width: 126px;
    height: 43px;

    @include media-breakpoint-down(lg) {
        width: 120px;
        height: 40px;
    }

    @media (max-width: 400px) {
        width: 96px;
        height: 32px;
    }
}

.header__logo-claim {
    margin-left: rem(16px);
    width: 239px;
    height: 23px;

    @include media-breakpoint-down(lg) {
        width: 220px;
        height: 18px;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.header__logo-crew-kids {
    width: 196px;
    height: 43px;

    @include media-breakpoint-down(lg) {
        width: 190px;
        height: 40px;
    }

    @media (max-width: 440px) {
        width: 132px;
        height: 32px;
    }
}



// header social
.col--header-social {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.header__social-item {
    color: var(--color-grey-800);
    text-decoration: none;
    margin-left: rem(28px);
    transition: color 0.2s;

    @include media-breakpoint-down(xxl) {
        margin-left: rem(20px);
    }

    &:nth-of-type(1) {
        margin-left: 0;
    }
}



// header search
.col--header-search {
    padding: 0 rem(80px) 0 rem(40px);
    position: relative;

    @include media-breakpoint-down(xxl) {
        padding-left: rem(20px);
        padding-right: rem(20px);
    }

    @include media-breakpoint-down(xl) {
        padding-left: rem(30px);
        padding-right: rem(10px);
    }

    @include media-breakpoint-down(lg) {
        padding: 0;
    }
}

.header__search {
    position: relative;

    @include media-breakpoint-down(xl) {
        display: none;
    }

    &.is--active {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;

        @media (max-width: 480px) {
            max-width: calc(100vw - 140px);
        }
    }
}

.search__toggle {
    border: none;
    border-radius: 50%;
    background: var(--color-white) url("../img/icon/search.svg") no-repeat center;
    background-size: 18px;
    color: var(--color-grey-800);
    display: none;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    padding: 0;
    cursor: pointer;
    transition: color 0.2s;

    @include media-breakpoint-down(xl) {
        display: flex;
    }

    @include media-breakpoint-down(lg) {
        background-color: transparent;
    }

    .icon {
        width: 18px;
        height: 18px;
        display: none;
        z-index: -1;

        @include media-breakpoint-down(xl) {
            display: block;
        }
    }

    &:hover,
    &:focus {
        color: var(--color-primary);
    }
}

.form__control.header__search-control {
    border-color: var(--color-white);
    background-color: var(--color-white);
    border-radius: 60px;
    font-size: rem(17px);
    color: var(--color-grey-800);
    width: 200px;
    max-width: 100%;
    height: 42px;
    padding-left: 50px;
    transition: width 0.2s;

    @include media-breakpoint-down(xl) {
        width: 250px;
    }

    &:focus {
        @include boxshadow;
        border-color: var(--color-grey-700);
        outline: none;

        @include media-breakpoint-up(xl) {
            width: 260px;
        }
    }

    &::placeholder {
        color: var(--color-grey-800);
        opacity: 1;
    }
}

.header__search-button {
    color: var(--color-grey-800);
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 13px;
    left: 18px;
    transition: color 0.2s;
    cursor: pointer;

    .icon {
        width: 18px;
        height: 18px;
    }

    &:hover,
    &:focus {
        color: var(--color-primary);
    }
}



// header control button/link
.col--header-control {
    display: flex;

    @include media-breakpoint-down(lg) {
        padding-left: rem(10px);
    }

    @media (max-width: 400px) {
        padding-left: rem(4px);
    }
}

.button.button--dark.button--login {
    background-color: var(--color-grey-700);
    border-color: var(--color-grey-700);
    font-size: rem(17px);
    font-weight: 400;
    height: 42px;
    margin-left: rem(34px);

    .icon {
        width: 20px;
        min-width: 20px;
        height: 20px;

        &.icon--left {
            margin-left: -4px;
        }
    }

    @include media-breakpoint-down(xxl) {
        margin-left: rem(20px);
    }

    @include media-breakpoint-down(lg) {
        color: var(--color-black) !important;
        background-color: transparent !important;
        border: none;
        font-size: 0;
        margin-left: 0;
        padding: 0;
        width: 42px;

        &:hover,
        &:focus {
            color: var(--color-primary) !important;
        }

        .icon {
            width: 24px;
            min-width: 24px;
            height: 24px;

            &.icon--left {
                margin: 0;
            }
        }
    }
}

.link.link--dark.link--registration {
    color: var(--color-grey-800);
    font-size: rem(17px);
    font-weight: 400;

    @include media-breakpoint-down(xxl) {
        display: none;
    }
}

.link.link--avatar {
    @include media-breakpoint-up(lg) {
        font-size: rem(17px);
        font-weight: 400;
        margin-right: $spacer * 1.25;
    }

    @include media-breakpoint-up(xxl) {
        margin-right: $spacer * 1.875;
    }

    .avatar {
        @include boxshadow;

        @include media-breakpoint-up(lg) {
            margin-right: $spacer * 0.9375;
        }
    }
}

.username {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;

    @include media-breakpoint-down(xxl) {
        max-width: 100px;
    }

    @include media-breakpoint-down(xl) {
        max-width: 60px;
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.link.link--logout {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}