.tabs {
    margin: 0 0 ($spacer * 4.5) 0;
    display: block;

    @include media-breakpoint-down(xl) {
        margin-bottom: $spacer * 4;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: $spacer * 3;
    }
}

.tabs__list {
    padding: 0;
    margin: 0 -20px;
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;

    /*@include media-breakpoint-down(xl) {
    flex-wrap: wrap;
    white-space: normal;
  }*/

    @include media-breakpoint-down(lg) {
        margin: 0 -10px;
    }

    @include media-breakpoint-down(sm) {
        flex-flow: column;
        white-space: normal;
    }
}

.tabs__item {
    margin: 0;
    padding: 0 20px;
    //flex: 1 0 0%;
    //width: 100%;
    flex: 0 0 auto;
    max-width: 100%;
    width: 33.33333333%;
    list-style: none;

    /*@include media-breakpoint-down(xl) {
    width: 50%;
  }*/

    @include media-breakpoint-down(lg) {
        padding: 0 10px;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: $spacer;
        width: 100%;

        &:nth-last-of-type(1) {
            margin-bottom: 0;
        }
    }

    &::before {
        display: none;
    }
}

.tab {
    border: none;
    background-color: var(--color-grey-100);
    white-space: normal;
    text-decoration: none;
    font-weight: 700;
    color: var(--color-black);
    position: relative;
    cursor: pointer;
    text-align: left;
    padding: ($spacer * 2.25) ($spacer * 2.25) ($spacer * 1.875) ($spacer * 6.25);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: color 0.2s, box-shadow 0.2s, transform 0.2s;

    @include media-breakpoint-down(lg) {
        padding: ($spacer * 1.75) ($spacer * 1.75) ($spacer * 1.5) ($spacer * 5);
    }

    @include media-breakpoint-between(sm, md) {
        padding: ($spacer * 3.5) ($spacer * 1.25) $spacer (
            $spacer * 1.25
        );
}

@include media-breakpoint-down(sm) {
    padding: ($spacer * 1.25) ($spacer * 1.25) ($spacer * 1) ($spacer * 4);
}

&:hover,
&:focus {
    color: var(--color-black);
    @include boxshadow;
    transform: translateY(-5px);

    .tab__heading {
        text-decoration: none;
    }
}

&.is--active {
    background: var(--color-black);
    border: none;
    color: var(--color-white);
    box-shadow: none;

    &:hover,
    &:focus {
        @include boxshadow;
    }

    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: var(--color-black) transparent transparent transparent;
    }

    .tab__heading {
        text-decoration: none;
    }

    .tab__icon {
        color: var(--color-white);
    }
}
}

.tab__icon {
    color: var(--color-grey-400);
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    left: $spacer * 2;
    top: $spacer * 2.25;
    transition: color 0.2s;

    @include media-breakpoint-down(lg) {
        width: 36px;
        height: 36px;
        left: $spacer * 1.5;
        top: $spacer * 1.9375;
    }

    @include media-breakpoint-down(md) {
        width: 25px;
        height: 25px;
        left: $spacer * 1.25;
        top: $spacer * 1.125;
    }
}

.tab__heading {
    font-size: rem(22px);
    line-height: rem(17px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include underline;

    @include media-breakpoint-down(md) {
        font-size: rem(19px);
    }
}

.tab__text {
    display: block;
    font-weight: 400;
    font-size: rem(16px);
    margin-top: $spacer * 0.5;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0.6;
}