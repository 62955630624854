// gallery
.gallery,
.box.gallery {
    margin-bottom: 0;
    padding-bottom: 0;

    &.gallery--offset {
        margin-top: $spacer * 2.5;
        margin-bottom: $spacer * 2;
    }
}

.gallery__item {
    margin: 0 0 ($spacer * 2.25) 0;

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer * 1.375;
    }
}

.gallery__picture {
    background-color: var(--color-white);
    display: block;
    aspect-ratio: 1/1;
    position: relative;
    transition: box-shadow 0.3s, transform 0.2s;
    @include boxshadow;
    @include watermark;

    &:hover,
    &:focus {
        transform: translate3d(0, -4px, 0);
    }

    img {
        margin: 0;
        display: block;
        max-width: 100%;
        position: relative;
        z-index: 1;
    }
}

.gallery__title {
    @extend .h5;
    margin: ($spacer * 1) 0 0 0;
}

.gallery__link {
    margin: ($spacer * 1) 0 0 0;
}

body.mfp-zoom-out-cur {

    .section,
    .header,
    .footer,
    .navigation,
    .newsletter {
        filter: blur(6px);
    }
}

.mfp-bottom-bar {
    padding-top: 6px;
}

.mfp-counter {
    top: 2px;
}

.mfp-figure::after,
.mfp-iframe-scaler iframe {
    @include boxshadow;
}