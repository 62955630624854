.body--ms {

    // header
    .header {
        background-color: transparent;
        border-bottom: 1px solid rgba($white-color, 0.15);
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            .container::before {
                display: none;
            }
        }

        .row {
            flex-wrap: nowrap;
        }
    }

    .header__social-item {
        color: var(--color-white);

        &:hover,
        &:focus,
        &:active {
            color: var(--color-primary);
        }
    }

    // sidebar
    .issue__sidebar {
        margin-top: $spacer * -31.875;
        margin-bottom: $spacer * 3.5;
        border: 1px solid var(--color-grey-100);
        background-color: var(--color-white);
        position: relative;
        z-index: 100;

        @include media-breakpoint-down(xxl) {
            margin-top: $spacer * -26;
        }

        @include media-breakpoint-down(xl) {
            margin-top: 0;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: $spacer * 2.875;
        }

        .sidebar__control {
            margin-bottom: 0;
            padding: $spacer * 2.25;

            @include media-breakpoint-down(xl) {
                padding: $spacer * 1.625;
            }
        }

        .sidebar__control-inner {
            text-align: center;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;

            @include media-breakpoint-down(sm) {
                padding-top: $spacer * 1.5;
                padding-left: 0;
            }
        }

        .infobar__image {
            background-color: var(--color-white);

            @include media-breakpoint-down(md) {
                width: 100px;
                max-width: 100%;
                margin: 0 auto;
            }
        }

        .infobar__title {
            margin-bottom: $spacer;

            a {
                @include underline;

                &:hover,
                &:focus {
                    color: var(--color-primary);
                    text-decoration: none;
                }
            }
        }

        .infobar__price {
            margin-bottom: $spacer * 1.5;
        }

        .infobar__offset {
            margin-top: $spacer * 0.75;

            @include media-breakpoint-down(lg) {
                text-align: center;
            }
        }

        .infobar__dl {
            @include media-breakpoint-up(xl) {

                dd,
                dt {
                    font-size: rem(17px);
                }
            }
        }
    }

    .issue__meta {
        padding: ($spacer * 2.25) ($spacer * 2.25) 0 ($spacer * 2.25);

        @include media-breakpoint-down(xl) {
            padding: ($spacer * 1.625) ($spacer * 1.625) 0 ($spacer * 1.625);
        }
    }


    // content
    .detail__text {
        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }
}


// subheader
.hero {
    background-color: var(--color-grey-100);
    background-position: top center;
    background-repeat: repeat-x;
    margin-bottom: $spacer * 4.375;
    padding-top: $spacer * 10;
    width: 100%;
    height: 600px;
    position: relative;

    @include media-breakpoint-down(xxl) {
        height: 505px;
    }

    @include media-breakpoint-down(xl) {
        margin-bottom: $spacer * 2.75;
        height: 360px;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer * 2.125;
        padding-top: $spacer * 8;
        height: 340px;
    }

    @include media-breakpoint-down(md) {
        height: 280px;
        padding-top: 90px;
    }

    @media (max-width: 440px) {
        height: 260px;
        padding-top: 80px;
    }

    & > .container {
        height: 100%;
        position: relative;

        @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.hero__title {
    margin: 0;
}

.hero__logo {
    margin: 0;
    width: 480px;
    height: auto;
    max-width: 100%;
    display: block;
    position: relative;
    z-index: 100;

    @include media-breakpoint-down(xxl) {
        width: 360px;
    }

    @include media-breakpoint-down(xl) {
        width: 320px;
    }

    @include media-breakpoint-down(lg) {
        width: 280px;
    }

    @include media-breakpoint-down(md) {
        margin: 0 auto;
    }
}

.hero__image {
    margin: 0;
    display: block;
    width: 900px;
    height: 500px;
    max-width: 100%;
    left: 0;
    bottom: $spacer * -2;
    position: absolute;
    z-index: 1;

    @include media-breakpoint-down(xxl) {
        width: 600px;
        height: 333px;
    }

    @include media-breakpoint-down(xl) {
        width: 480px;
        height: 267px;
        left: auto;
        right: 0;
        bottom: $spacer * -1;
    }

    @include media-breakpoint-down(lg) {
        width: 380px;
        height: 211px;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}


// rozcestnik
.signpost {
    @include media-breakpoint-down(lg) {
        display: none;
    }

    &.signpost--responsive {
        border-bottom: 0;
        margin-bottom: 0;
        display: block;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .col--links {
        gap: ($spacer * 0.5) ($spacer * 2);

        @include media-breakpoint-down(xxl) {
            flex-direction: row;
            gap: ($spacer * 0.5) ($spacer * 1.5);
        }

        @include media-breakpoint-down(lg) {
            justify-content: center;
        }

        @include media-breakpoint-down(md) {
            font-size: rem(15px);
            margin-top: 0;
            gap: ($spacer * 0.5) ($spacer * 0.8125);
        }

        .link {
            margin: 0;
        }
    }
}