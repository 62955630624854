// post
.post {
    margin-bottom: $spacer * 2.375;
    display: flex;
    position: relative;

    @media (max-width: 400px) {
        margin-bottom: $spacer * 1.875;
    }
}

.post__side {
    margin-right: rem(30px);
    flex: 0 0 auto;
    width: 116px;
    max-width: 100%;

    @media (max-width: 480px) {
        margin-right: rem(20px);
        width: 90px;
    }

    @media (max-width: 400px) {
        width: 68px;
    }
}

.post__image {
    background-color: var(--color-grey-100);
    position: relative;
    display: block;
    aspect-ratio: 1/1;
    transition: box-shadow 0.3s;

    @include boxshadow;
    @include watermark;

    img {
        display: block;
        max-width: 100%;
        position: relative;
        z-index: 1;
    }
}

.post__container {
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;
}

.post__heading {
    margin-bottom: rem(5px);

    a {
        color: var(--color-primary);
        @include underline;

        &:hover,
        &:focus {
            color: var(--color-primary);
            text-decoration: none;
        }
    }
}

.heading__comment {
    font-weight: 400;
    font-size: rem(15px);
    line-height: var(--body-line-height);
    vertical-align: middle;
    margin-left: rem(12px);
}

.post__meta {
    margin: 0 0 rem(12px) 0;
    padding: 0;
}

.meta__item {
    font-size: rem(15px);
    color: var(--color-grey-400);
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;

    &::before {
        display: none;
    }

    &::after {
        content: "";
        background-color: var(--color-grey-400);
        border-radius: 50%;
        vertical-align: middle;
        display: inline-block;
        margin: 0 rem(5px);
        width: 3px;
        height: 3px;
    }

    &:nth-last-of-type(1)::after {
        display: none;
    }

    a {
        color: var(--color-grey-400);
        @include underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .avatar {
        vertical-align: top;
        margin: 0 rem(10px) 0 0;
    }
}

.post__perex {
    margin-bottom: rem(14px);

    @include media-breakpoint-down(xl) {
        @include linecrop(3);
    }
}

.post__buy {
    text-align: center;
    margin-top: rem(17px);

    @media (max-width: 480px) {
        .button {
            padding: rem(8px) rem(15px);
        }
    }
}

.post__price {
    font-size: rem(15px);
    margin: 0 0 rem(12px) 0;

    strong {
        color: var(--color-primary);
        padding: 0 rem(2px);
    }

    del {
        font-size: rem(13px);
        padding: 0 rem(2px);
    }
}

.avatar {
    @include avatar(42px);
    display: inline-block;

    &.avatar--small {
        border: 1px solid var(--color-grey-200);
        width: 20px;
        height: 20px;
    }
}

.tags {
    text-align: start;
    position: absolute;
    bottom: 45px;
    left: 0;

    @include media-breakpoint-down(xxl) {
        bottom: 20px;
    }

    .tag {
        font-weight: 500;
        margin-top: rem(10px);
        float: left;
        clear: left;

        @include media-breakpoint-down(xxl) {
            font-size: rem(11px);
            margin-top: rem(8px);
        }
    }
}



// post big news
.post--aktualita-velka {
    margin-bottom: $spacer * 3.5;

    @include media-breakpoint-down(sm) {
        margin-bottom: $spacer * 2.5;
    }

    .post__side {
        margin-right: rem(34px);
        width: 230px;

        @include media-breakpoint-down(xxl) {
            width: 170px;
        }

        @include media-breakpoint-down(sm) {
            width: 116px;
        }

        @media (max-width: 480px) {
            margin-right: rem(20px);
            width: 84px;
        }
    }

    .post__container {
        margin-right: rem(34px);

        @include media-breakpoint-down(xxl) {
            margin-right: 0;
        }

        @include media-breakpoint-down(xl) {
            margin-right: rem(120px);
        }

        @include media-breakpoint-down(lg) {
            margin-right: rem(34px);
        }

        @include media-breakpoint-down(md) {
            margin-right: 0;
        }
    }

    .post__meta {
        margin: 0 0 rem(10px) 0;
    }

    .post__heading,
    .post__perex {
        margin: 0 0 rem(24px) 0;
    }

    .post__perex {
        @include media-breakpoint-down(md) {
            @include linecrop(9);
        }

        @include media-breakpoint-down(sm) {
            @include linecrop(5);
        }
    }

    .tags {
        @media (max-width: 480px) {
            display: none;
        }
    }
}



// post video
.post--video {
    background-color: var(--color-grey-200);
    display: block;
    margin: 0;
    height: 100%;
    min-height: 288px;
    max-height: 290px;
    overflow: hidden;

    @include watermark;

    @media (max-width: 1499px) {
        min-height: 224px;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 290px;
    }

    @include media-breakpoint-down(lg) {
        height: 250px;
        max-height: 100%;
    }

    @include media-breakpoint-down(md) {
        height: 220px;
        min-height: 220px;
    }

    @include media-breakpoint-down(sm) {
        height: 200px;
        min-height: 200px;
    }

    &.video--wide {
        /*@include media-breakpoint-up(xl) {
      height: 250px;
    }

    @include media-breakpoint-up(xxl) {
      height: 290px;
    }

    @include media-breakpoint-down(lg) {
      height: 250px;
      max-height: 100%;
    }*/

        @media (max-width: 399px) {
            height: 150px;
            min-height: 150px;
        }
    }

    /*&::after {
    content: '';
    background: linear-gradient(0deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0.78) 71%, rgba(255,255,255,0) 100%);
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;

    @include media-breakpoint-down(xl) {
      height: 33%;
    }
  }*/
}

.post--video__bg {
    width: 100%;
    /*height: 100%;
  object-fit: cover;
  object-position: center top;*/
    margin: 0;
    position: relative;
    z-index: 0;
}

.post--video__container {
    //background-color: rgba($grey-200-color, 0.8);
    background-color: var(--color-grey-200);
    width: 100%;
    padding: ($spacer * 0.875) ($spacer * 7) ($spacer * 0.875) ($spacer * 1.625);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 20;

    @include media-breakpoint-down(sm) {
        padding-left: $spacer * 1.25;
        padding-right: $spacer * 6;
    }
}

.post--video__play {
    background-color: var(--color-white);
    color: var(--color-primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 28px;
    top: -30px;
    transition: transform 0.2s;

    @include media-breakpoint-down(sm) {
        width: 50px;
        height: 50px;
        right: 26px;
        top: -27px;
    }

    .post--video:hover & {
        transform: scale(1.1);
    }

    svg {
        width: 30px;
        height: 34px;
        //margin-left: 3px;

        @include media-breakpoint-down(sm) {
            width: 26px;
            height: 30px;
        }
    }
}

.post--video__heading {
    margin: 0 !important;
    display: flex;
    align-items: center;

    > span {
        @include underline;
        color: var(--color-black);
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        //width: calc(100% - 24px);
        overflow: hidden;

        &:hover,
        &:focus,
        .post--video:hover & {
            color: var(--color-black);
            text-decoration: none;
        }
    }

    > svg {
        margin-left: $spacer * 0.375;
        min-width: 17px;
    }
}

/*.post--video__label {
  font-size: rem(18px);
  font-weight: 700;
  color: var(--color-primary);
  margin: 0 0 rem(8px) 0;
}*/

.post--video__yt {
    color: var(--color-black);
    width: 55px;
    height: 13px;
    position: absolute;
    bottom: 22px;
    right: 36px;
    opacity: 0.3;
    transition: opacity 0.2s;
}



// post mini
.post--mini {
    margin-bottom: $spacer * 0.875;

    .post__heading {
        font-size: rem(16px);
        font-weight: 500;
        margin-bottom: $spacer * 0.375;

        .badge {
            margin-left: $spacer * 0.375;
        }
    }

    .post__meta {
        margin-bottom: $spacer * 0.1875;
    }

    .meta__item {
        vertical-align: middle;
    }

    .post__perex {
        @include linecrop(1);
        margin-bottom: 0;
    }
}


// post komentar
.post--komentar {
    margin-bottom: $spacer * 1.125;

    .post__perex {
        @include media-breakpoint-down(xl) {
            @include linecrop(2);
        }
    }
}


// post recenze kratka
.post--recenze-kratka {
    margin-bottom: $spacer * 1.5;

    .post__meta {
        margin-bottom: $spacer * 0.375;
    }
}


// post bazar
.post--bazar {
    margin-bottom: $spacer * 3;

    @media (max-width: 400px) {
        margin-bottom: $spacer * 2;
    }

    .post__perex {
        padding-left: $spacer * 1.875;
        position: relative;

        &::before {
            content: "";
            background-image: linear-gradient(45deg, rgba(0, 0, 0, .06) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .06) 50%, rgba(0, 0, 0, .06) 75%, transparent 75%, transparent);
            background-size: 9px 9px;
            width: 7px;
            height: calc(100% - 6px);
            display: block;
            top: 4px;
            left: 7px;
            position: absolute;
        }
    }
}



// crop content bazar
.link--crop {
    display: none;
    cursor: pointer;

    .icon {
        transition: transform 0.2;
    }

    &.is--active {
        display: inline-flex;
    }

    &.is--open {
        .icon {
            transform: rotate(180deg);
        }
    }
}

.description--crop {
    height: calc(var(--crop-height) * 1px);
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        width: 100%;
        height: 90px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }

    &.description--open {
        height: auto;
        display: block;

        &::after {
            display: none;
        }
    }
}