*,
*::before,
*::after {
    box-sizing: border-box;
}


// document

body {
    margin: 0;
    padding: 0;
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    font-weight: var(--body-font-weight);
    line-height: var(--body-line-height);
    text-align: start;
    color: var(--body-color);
    background-color: var(--body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;

    &.body--white {
        background-color: var(--color-white);
    }
}


// typo

abbr[title] {
    text-decoration: underline dotted;
    cursor: help;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

hr {
    border: 0;
    border-top: 1px solid var(--border-color);
    margin: 4rem 0;
    padding: 0;
    opacity: 1;
    overflow: visible;

    @include media-breakpoint-down(md) {
        margin: 4rem 0;
    }
}

figure {
    margin: 0 0 1rem;
}

img,
svg {
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

label {
    display: inline-block;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

[role="button"] {
    cursor: pointer;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

iframe {
    border: 0;
}

[hidden] {
    display: none !important;
}