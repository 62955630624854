// colors
$grey-110-color: #F4F4F4;
$grey-100-color: #EEEEEE;
$grey-200-color: #E7E7E7;
$grey-300-color: #BCBABA;
$grey-400-color: #7F7F7F;
$grey-500-color: #707070;
$grey-600-color: #999999;
$grey-700-color: #2A2A2A;
$grey-800-color: #444;

$white-color: #FFF;
$black-color: #000;

$primary-color: #ED1C24;
$primary-dark-color: darken($primary-color, 7%);
$primary-light-color: lighten($primary-color, 15%);

$success-color: #32CB7C;
$success-dark-color: darken($success-color, 7%);
$success-light-color: lighten($success-color, 15%);
$warning-color: #E9B60D;
$danger-color: #E3566C;
$info-color: #29A2D8;

$border-color: #DDDDDD;
$body-color: $black-color;
$body-bg: $white-color;


// fonts
$font-family-base: 'DM Sans', sans-serif;

$font-size-base: 1rem;
$font-size-base-px: 16px;
$font-weight-base: 400;
$line-height-base: 1.5;


// heading
$headings-font-family: $font-family-base;
$headings-line-height: 1.25;


// crop content
$crop-height: 180;