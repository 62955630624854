.filter__list {
    margin: 0;
    padding: 0;
    display: inline-block;
}

.filter__item {
    margin: 0 0 0 rem(6px);
    padding: 0;
    display: inline-block;

    &:nth-of-type(1) {
        margin-left: 0;
    }

    &::before {
        display: none;
    }

    &.item--category {
        border-radius: 70px;
        background-color: var(--border-color);
        display: inline-flex;
    }
}

.button {
    &.button--switcher {
        color: var(--color-black);
        border-color: transparent;
        background-color: transparent;

        &:hover {
            background-color: var(--color-grey-300);
            border-color: var(--color-grey-300);
        }

        &.is--active {
            background-color: var(--color-black);
            border-color: var(--color-black);
            color: var(--color-white);
        }
    }

    &.button--checkbox {
        @extend .button--outline;
        @extend .button--grey;
        background-color: var(--color-white);

        &:hover,
        &:focus {
            background-color: var(--color-white);
        }

        &.is--active {
            border-color: var(--color-primary);
            background-color: var(--color-white);
            color: var(--color-black);

            &::before {
                content: "";
                background: url('../img/icon/sticker-check.svg') no-repeat 0 0;
                background-size: contain;
                width: 15px;
                height: 15px;
                top: -5px;
                right: -2px;
                position: absolute;
            }

            &:hover,
            &:focus {
                border-color: var(--color-black);
                background-color: var(--color-white);
                color: var(--color-black);
            }
        }
    }
}