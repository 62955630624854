// infobar
.infobar {
    background-color: transparent;
    //border-bottom: 1px solid var(--border-color);
    margin: 0 0 ($spacer * 5.5) 0;
    //padding: 0 0 ($spacer * 3.375) 0;
    display: block;
    position: relative;

    @include media-breakpoint-down(xl) {
        margin-bottom: $spacer * 4;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer * 3;
    }

}

.infobar__grid {
    display: grid;
    grid-gap: ($spacer * 1.25) ($spacer * 4);
    grid:
        "cover description"
        "cover control"
        / 310px;
    grid-template-rows: auto 1fr;

    @include media-breakpoint-down(xl) {
        grid-gap: ($spacer * 0.5) ($spacer * 2.375);
        grid:
            "cover description"
            "cover control"
            / 230px;
    }

    @include media-breakpoint-down(lg) {
        grid-gap: 0 ($spacer * 2.125);
        grid:
            "cover description"
            "control control"
            / 200px;
    }

    @include media-breakpoint-down(md) {
        grid-gap: ($spacer * 0.5) ($spacer * 1.675);
        grid:
            "cover description"
            "control control"
            / 140px;
    }

    @media (max-width: 480px) {
        grid-gap: $spacer 0;
        grid:
            "cover cover"
            "control control"
            "description description";
    }
}

.infobar__cover {
    grid-area: cover;
}

.infobar__description {
    padding: ($spacer * 1.75) 200px 0 0;
    grid-area: description;

    @include media-breakpoint-down(xxl) {
        padding-right: 100px;
    }

    @include media-breakpoint-down(xl) {
        padding: ($spacer * 0.5) 0 0 0;
    }

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    @media (max-width: 480px) {
        border-bottom: 1px solid var(--border-color);
        padding-bottom: $spacer * 1.5;
    }
}

.infobar__control {
    grid-area: control;
    /*display: flex;
  align-items: flex-end;

  .box {
    width: 100%;
  }*/

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    @media (max-width: 480px) {
        margin-bottom: $spacer * 1.5;
    }
}



// heading
.infobar__heading {
    @include media-breakpoint-down(xl) {
        font-size: rem(36px);
        margin-bottom: $spacer * 2.25;
    }

    @include media-breakpoint-down(lg) {
        font-size: rem(34px);
        margin-bottom: $spacer * 1.875;
    }

    @media (max-width: 480px) {
        text-align: center;
    }
}



// cover
.infobar__image {
    background-color: var(--color-grey-100);
    position: relative;
    display: block;
    aspect-ratio: 1/1;
    @include boxshadow;
    @include watermark;

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer * 2.25;
    }

    @media (max-width: 480px) {
        width: 160px;
        max-width: 100%;
        margin: 0 auto ($spacer * 1.125);
    }

    img {
        margin: 0;
        display: block;
        max-width: 100%;
        position: relative;
        z-index: 1;
    }
}



// control
.col--links {
    display: flex;

    @include media-breakpoint-down(xxl) {
        flex-wrap: wrap;
        flex-direction: column;
    }

    @include media-breakpoint-down(sm) {
        margin-top: $spacer * 1.5;
    }

    .link {
        @include media-breakpoint-up(xxl) {
            margin-right: 3rem;

            &:nth-last-of-type(1) {
                margin-right: 0;
            }
        }

        @include media-breakpoint-down(xxl) {
            justify-content: flex-end;

            @include media-breakpoint-down(sm) {
                justify-content: center;
            }

            span {
                order: 2;
            }

            .icon--right {
                margin-left: 0;
                margin-right: $spacer * 0.5;
            }
        }

        @include media-breakpoint-down(md) {
            margin-top: $spacer * 0.25;
            margin-bottom: $spacer * 0.25;
        }
    }
}

.infobar__price {
    font-size: rem(16px);
    margin: 0;

    @include media-breakpoint-down(md) {
        margin-bottom: $spacer * 0.75;
    }

    strong {
        color: var(--color-primary);
        padding: 0 rem(1px);
        display: block;
    }

    span {
        color: var(--color-grey-500);
        font-size: rem(14px);
        display: block;
    }
}


// meta
.infobar__dl {
    @include media-breakpoint-up(xl) {

        dd,
        dt {
            font-size: rem(18px);
        }
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: $spacer * 0.5;

        dd,
        dt {
            font-size: rem(16px);
        }
    }
}

.infobar__icon {
    color: var(--color-grey-600);
    margin: -3px ($spacer * 0.5) 0 0;

    @include media-breakpoint-down(md) {
        margin: -2px ($spacer * 0.25) 0 0;
        width: 14px;
        height: 14px;
    }
}



// infobar news
.infobar--news {
    .row--meta {
        @include media-breakpoint-between(xl, xxl) {
            margin-left: $spacer * 3.75;
        }
    }

    .infobar__heading {
        @media (max-width: 480px) {
            text-align: start;
        }
    }

    .infobar__control {
        @include media-breakpoint-down(lg) {
            margin-top: $spacer;
        }

        @media (max-width: 480px) {
            margin-bottom: 0;
        }
    }

    .col--links {
        @include media-breakpoint-down(xxl) {
            flex-wrap: nowrap;
            flex-direction: row;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 0;
        }

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            flex-direction: column;
        }

        .link {
            @include media-breakpoint-up(sm) {
                margin-right: 3rem;

                &:nth-last-of-type(1) {
                    margin-right: 0;
                }
            }

            @include media-breakpoint-down(xxl) {
                span {
                    order: 1;
                }

                .icon--right {
                    order: 2;
                    margin-right: 0;
                    margin-left: $spacer * 0.5;
                }
            }
        }
    }
}

.infobar__author {
    text-align: end;
    color: var(--color-grey-400);
}