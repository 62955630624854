// series
.series {
    margin-bottom: $spacer * 2.375;
    display: flex;
    position: relative;

    @media (max-width: 400px) {
        margin-bottom: $spacer * 1.875;
    }
}

.series__side {
    margin-right: rem(30px);
    flex: 0 0 auto;
    width: 146px;
    max-width: 100%;

    @include media-breakpoint-down(lg) {
        width: 116px;
    }

    @media (max-width: 480px) {
        margin-right: rem(20px);
        width: 90px;
    }

    @media (max-width: 400px) {
        width: 68px;
    }
}

.series__images {
    position: relative;
    display: block;
}

.series__image {
    //background-color: var(--color-grey-100);
    width: calc(100% - 16px);
    display: block;
    //aspect-ratio: 1/1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: box-shadow 0.3s;

    @include boxshadow;
    //@include watermark;

    &:nth-of-type(2) {
        z-index: 1;
        top: 8px;
        left: 8px;
    }

    &:nth-of-type(3) {
        z-index: 2;
        top: 16px;
        left: 16px;
    }

    img {
        display: block;
        max-width: 100%;
        position: relative;
        z-index: 1;
    }
}

.series__container {
    padding: rem(14px) 0 0 0;
    width: 100%;
    max-width: 100%;
    flex: 1 0 0%;
}

.series__heading {
    margin-bottom: $spacer * 1;

    a {
        color: var(--color-primary);
        @include underline;

        &:hover,
        &:focus {
            color: var(--color-primary);
            text-decoration: none;
        }
    }
}

.series__meta {
    margin-bottom: $spacer;

    dt,
    dd {
        color: var(--color-grey-500);
        font-size: rem(16px);
    }

    a {
        color: var(--color-grey-500);
    }

    .badge {
        color: var(--color-grey-500);
        background: none;
        padding: 0;
    }

    @include media-breakpoint-down(lg) {
        dd {
            margin-bottom: $spacer * 0.375;
        }
    }
}