.modal__close {
    line-height: 1;
    color: var(--color-white);
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    top: 50%;
    right: rem(30px);
    transform: translateY(-50%);
    opacity: 0.75;
    float: none;
    position: absolute;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover,
    &:focus {
        outline: none;
        opacity: 1;
    }
}

.modal__bubble {
    color: var(--color-white);
    position: absolute;
    top: 79%;
    right: -250px;
    opacity: 0.18;
    z-index: 0;

    @include media-breakpoint-down(sm) {
        right: 50%;
        transform: translateX(50%);
    }
}

.modal-header {
    background-color: var(--color-primary);
    border: none;
    border-radius: 0;
    padding: rem(22px) rem(30px);
    z-index: 10;
    position: relative;

    @include media-breakpoint-down(sm) {
        padding: rem(20px) rem(24px);
    }
}

.modal-title {
    @extend .h3;
    color: var(--color-white);
    margin: 0;
}

.modal-dialog {
    border-radius: 0;
    box-shadow: none;
    margin-top: 10vh;
    max-width: 560px;

    @include media-breakpoint-down(sm) {
        margin: rem(40px) rem(20px);
    }
}

.modal-content {
    border-radius: 0;
    background-color: var(--color-white);
}

.modal-body {
    padding: rem(30px);

    @include media-breakpoint-down(sm) {
        padding: rem(24px);
    }
}

.modal-footer {
    background-color: var(--color-grey-100);
    font-size: var(--body-font-size);
    border: 0;
    border-radius: 0;
    text-align: center;
    padding: rem(24px) rem(30px);
    display: block;

    @include media-breakpoint-down(sm) {
        padding: rem(20px) rem(24px);
    }

    & > p {
        margin: 0;
    }
}

.modal-backdrop.show {
    opacity: 0.65;
}

body.modal-open {

    .section,
    .footer,
    .header,
    .navigation,
    .newsletter {
        filter: blur(6px);
    }
}



// modal login
.modal--login .modal-dialog {
    @include media-breakpoint-up(sm) {
        max-width: 375px;
    }
}



// welcome login
.modal--welcome {
    .modal-dialog {
        @include media-breakpoint-up(sm) {
            max-width: 630px;
        }
    }

    .modal-header {
        padding-right: $spacer * 4;
    }
}