.detail__sidebar,
.detail__sidebar.box {
    margin-bottom: $spacer * 3.5;

    @include media-breakpoint-down(md) {
        margin-bottom: $spacer * 2.875;
    }

    &.sidebar--sticky {
        position: sticky;
        top: 0;
    }
}

.detail__sidecover {
    width: 100%;
    display: block;

    @include media-breakpoint-down(xxl) {
        max-width: 350px;
        margin-left: auto;
        margin-right: 0;
    }

    @include media-breakpoint-down(lg) {
        max-width: 180px;
        margin-left: 0;
    }
}

.detail__sidebar-comment,
.detail__sidebar-comment.box {
    margin-bottom: 0;

    @include media-breakpoint-down(lg) {
        margin-top: $spacer * 3;
        margin-bottom: $spacer * 1.5;
    }

    &.detail__sidebar-comment--selfcare {
        @include media-breakpoint-down(xl) {
            margin-top: 0;
            margin-bottom: $spacer * 3;
        }
    }
}

.detail__text {
    font-size: rem(17px);
    margin-bottom: $spacer * 2;

    @include media-breakpoint-down(xl) {
        font-size: var(--body-font-size);
    }
}



// product detail
.sidebar__control {
    background-color: var(--color-grey-100);
    margin-bottom: $spacer * 2.5;

    @media (max-width: 480px) {
        padding-top: $spacer * 1.75;
    }

    .infobar__image {
        margin-bottom: 0;
        max-width: 212px;
    }

    .infobar__price {
        @media (max-width: 480px) {
            text-align: center;
        }

        strong {
            line-height: 1.25;
        }
    }
}

.sidebar__control-inner {
    padding: ($spacer * 1.75) ($spacer * 2);

    @include media-breakpoint-between(lg, xl) {
        padding: ($spacer * 1.375) ($spacer * 1.5);
    }
}

.infobar__offset {
    text-align: center;
    margin-top: $spacer * 1.375;
    margin-bottom: 0;

    @include media-breakpoint-down(lg) {
        text-align: start;
    }

    @media (max-width: 480px) {
        text-align: center;
    }
}