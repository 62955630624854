/*
 * Bootstrap  v5.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */


// scss-docs-start import-stack
// Configuration
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";

// containers/breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1500px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1440px
);

$gutters: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    //5: $spacer * 3,
    5: $spacer * 2,
    //6: $spacer * 3.375,
    6: $spacer * 4,
    44: $spacer * 1.25,
    55: $spacer * 2.125,
);

@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";
// Only use the utilities we need
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-get-multiple($utilities,
        ("display",
            "order",
            "flex",
            "flex-direction",
            "justify-content",
            "align-items",
            "align-content",
            "align-self",
            "margin",
            "margin-x",
            "margin-y",
            "margin-top",
            "margin-end",
            "margin-bottom",
            "margin-start",
            "padding",
            "padding-x",
            "padding-y",
            "padding-top",
            "padding-end",
            "padding-bottom",
            "padding-start",
            "text-align",
        ));

// Layout & components
// @import "node_modules/bootstrap/scss/root";
//@import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/forms";
// @import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/nav";
// @import "node_modules/bootstrap/scss/navbar";
// @import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/accordion";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/spinners";
// @import "node_modules/bootstrap/scss/offcanvas";
// @import "node_modules/bootstrap/scss/placeholders";

// Helpers
//@import "node_modules/bootstrap/helpers";
@import "node_modules/bootstrap/scss/helpers/visually-hidden";

// Utilities
@import "node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack



// reset container
@include media-breakpoint-up(xl) {

    .container--narrow,
    .container.container--narrow {
        max-width: 900px;
    }
}


// responsive side offset
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    @include media-breakpoint-down(xs) {
        padding-left: $spacer * 1.25;
        padding-right: $spacer * 1.25;
    }
}


// col-xs 480px
.col--xs {
    @media (max-width: 480px) {
        flex-shrink: 0;
        width: 100%;
    }
}