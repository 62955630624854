// carousels
.slick-list {
    margin: 0 rem(-17px);
    transition: opacity 0.2s, filter 0.2s;

    @include media-breakpoint-down(lg) {
        margin: 0 rem(-10px);
    }

    @media (max-width: 480px) {
        margin: 0 rem(-20px);
        padding-left: 10px;
        padding-right: 10px;
    }
}

.slick-slide {
    margin: 0 rem(17px);

    @include media-breakpoint-down(lg) {
        margin: 0 rem(10px);
    }

    > div {
        height: 100%;
    }
}

.carousel__item {
    height: 100%;

    .card {
        display: none;

        .slick-initialized & {
            display: flex;
        }
    }
}

.slick-track {
    display: flex;
    //align-items: flex-start;
    align-items: stretch;
}

/*.slick-loading .slick-list {
  background: url('../img/icon/loader.gif') center center no-repeat;
}*/

.slick-arrow {
    background-color: var(--color-primary);
    color: var(--color-white);
    border: none;
    border-radius: 50%;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 45px;
    width: 45px;
    position: absolute;
    top: 32%;
    transform: translateY(-50%);
    z-index: 100;
    cursor: pointer;
    transition: background-color 0.2s;

    @include media-breakpoint-down(xxl) {
        top: 29%;
    }

    @include media-breakpoint-down(md) {
        height: 40px;
        width: 40px;
    }

    &:hover,
    &:focus {
        outline: none;
        background-color: var(--color-primary-light);
        color: var(--color-white);
    }

    &.slick-disabled {
        opacity: 0;
    }
}

.slick-prev {
    left: -23px;

    @include media-breakpoint-down(md) {
        left: -20px;
    }
}

.slick-next {
    right: -23px;

    @include media-breakpoint-down(md) {
        right: -20px;
    }
}



// wide caoursel
.wide-carousel {
    background-color: var(--color-grey-100);
    @include watermark;
    margin-top: -4px;
    height: 350px;
    transition: background-color 0.2s;

    &.slick-initialized {
        background-color: transparent;
        height: auto;

        &::before {
            display: none;
        }
    }

    .slick-track {
        padding-top: 4px;
        margin-left: 0;
    }

    .card {
        margin-bottom: rem(12px);

        @include media-breakpoint-down(lg) {
            margin-bottom: 0;
        }
    }
}



// banner caoursel
.banner-carousel {
    background-color: var(--color-grey-100);
    @include boxshadow;
    @include watermark;
    aspect-ratio: 915/289;
    overflow: hidden;
    position: relative;

    &.slick-initialized {
        overflow: visible;
    }

    .slick-list {
        margin: 0;
        padding: 0;
    }

    .slick-slide {
        margin: 0;

        & > div {
            display: flex;
        }
    }

    .slick-arrow {
        top: 50%;
    }
}