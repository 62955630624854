// events
.event {
    padding-left: 34px;
    padding-bottom: rem(34px);
    height: 100%;
    position: relative;
}

.event__container {
    background-color: var(--color-grey-100);
    padding: rem(20px) rem(30px) rem(25px) rem(60px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;
}

.event__heading {
    a {
        color: var(--color-primary);
        @include underline;

        &:hover,
        &:focus {
            color: var(--color-primary);
            text-decoration: none;
        }
    }
}

.event__date {
    text-align: center;
    font-size: rem(13px);
    color: var(--color-white);
    background-color: var(--color-black);
    padding: $spacer 0 ($spacer * 0.6125);
    width: 68px;
    min-height: 80px;
    position: absolute;
    top: 0;
    left: 0;

    @include boxshadow;

    b {
        font-size: rem(30px);
        font-weight: 700;
        line-height: 1;
        display: block;
    }
}