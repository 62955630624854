.footer {
    background-color: var(--color-black);
    padding-top: 140px;
    padding-bottom: 50px;
    position: relative;
    transition: filter 0.2s;

    @include media-breakpoint-down(lg) {
        padding-top: 130px;
        padding-bottom: 42px;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 170px;
        padding-bottom: 34px;
    }
}

.footer__logo {
    margin: 0 0 ($spacer * 3.125) 0;
    width: 120px;
    height: 43px;
    display: block;

    @include media-breakpoint-down(xl) {
        margin-bottom: $spacer * 2.375;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer * 2;
        /*width: 118px;
    height: 40px;*/
    }
}

.footer__navigation {
    margin: 0;
    padding: 0;

    @include media-breakpoint-down(xl) {
        margin-bottom: $spacer * 0.875;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer * 1.875;
    }

    & > li {
        list-style: none;
        vertical-align: top;
        display: inline-block;
        margin: 0 ($spacer * 3) 0 0;
        padding: 0;

        @include media-breakpoint-down(xxl) {
            margin-right: ($spacer * 2);
        }

        @include media-breakpoint-down(lg) {
            margin-right: 0;
            margin-bottom: $spacer * 0.25;
            display: block;
        }

        &:nth-last-of-type(1) {
            margin-right: 0;
        }

        &::before {
            display: none;
        }

        a {
            color: var(--color-grey-600);

            &:hover,
            &:focus {
                color: var(--color-white);
                text-decoration: none;
            }
        }
    }
}

.footer__social {
    background-color: var(--color-grey-700);
    display: block;
    margin: 0 0 ($spacer * 3.125) 0;
    padding: ($spacer * 1.125) ($spacer * 1.875);

    @include media-breakpoint-down(xl) {
        margin-bottom: $spacer * 2.375;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer * 2;
        padding: $spacer (
            $spacer * 1.375
        );
}
}

.footer__social-item {
    color: var(--color-white);
    text-decoration: none;
    margin: 0 ($spacer * 2) 0 0;
    transition: color 0.2s;

    @include media-breakpoint-down(lg) {
        margin-right: $spacer * 1.5;
    }

    &:nth-last-of-type(1) {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        color: var(--color-primary);
        text-decoration: none;
    }
}

.footer__copy {
    color: var(--color-grey-600);
    margin: 0;
}


// reCAPTCHA
.grecaptcha-badge {
    visibility: hidden !important;
}