// loader
%loader {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90px;
    height: 90px;
    transform: translate(-50%, -50%);
    background: url("../img/icon/loader.svg") no-repeat;
    background-size: cover;
    z-index: 1000;
    opacity: 0.25;
}



// snippets
.snippet {
    background-color: var(--color-grey-100);
    height: 100px;
    position: relative;
    transition: height 0.2s, background-color 0.2s;

    &.snippet--releasedlist {
        height: 470px;

        .heading-row {
            position: relative;
            z-index: 10;
        }

        &::before {
            top: calc(50% + 35px);
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 70px;
            background-color: var(--color-white);
            z-index: 1;
            transition: background-color 0.2s;

            @include media-breakpoint-down(xl) {
                height: 55px;
            }
        }
    }

    &.snippet--plannedsidelist {
        height: 845px;
    }

    &.snippet--commentlist {
        height: 500px;
    }

    &.snippet--articlelist {
        height: 450px;
    }

    &.snippet--reviewlist {
        height: 660px;
    }

    // initializade
    &.snippet--initialized {
        background-color: transparent;
        height: auto;

        &::before {
            display: none;
        }

        &::after {
            background-color: transparent;
        }
    }

    &::before {
        @extend %loader;
    }
}

.snippet--sticky {
    position: sticky;
    top: 0;
}



// ajax waiting
.wait {
    .wide-carousel {
        position: relative;

        &::after {
            @extend %loader;
            opacity: 0.5;
        }

        .slick-list {
            opacity: 0.1;
            filter: grayscale(1);
        }
    }
}