// navigation
.navigation {
    background-color: var(--color-grey-110);
    padding-top: rem(2px);
    padding-bottom: rem(26px);
    position: relative;
    z-index: 920;
    transition: filter 0.2s;

    /*
  background-color: var(--color-white);
  padding-top: 1.375rem;
  padding-bottom: 0;
  */


    @include media-breakpoint-down(lg) {
        background-color: var(--color-white);
        display: none;
        padding: rem(100px) rem(60px) rem(40px) rem(60px);
        position: fixed;
        top: 0;
        right: -100%;
        max-width: 50%;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        transition: right 0.4s;
    }

    @include media-breakpoint-down(md) {
        max-width: 75%;
    }

    @include media-breakpoint-down(sm) {
        max-width: 100%;
        padding: rem(80px) rem(40px) rem(30px) rem(40px);
    }

    .navigation__toggle {
        position: absolute;
        top: rem(36px);
        right: rem(60px);
        display: none;

        @include media-breakpoint-down(lg) {
            display: block;
        }

        @include media-breakpoint-down(sm) {
            top: rem(22px);
            right: rem(30px);
        }
    }

    .header__social {
        border-top: 1px solid var(--border-color);
        margin-top: rem(35px);
        padding-top: rem(35px);
        display: none;

        @include media-breakpoint-down(lg) {
            display: block;
        }
    }
}

.navigation__list {
    margin: 0;
    padding: 0;
}

.navigation__item {
    margin: 0 rem(20px) 0 0;
    padding: 0;
    list-style: none;
    line-height: 1;
    display: inline-block;
    position: static;

    @include media-breakpoint-down(xxl) {
        margin-right: rem(10px);
    }

    @include media-breakpoint-down(xl) {
        margin-right: rem(7px);
    }

    @include media-breakpoint-down(lg) {
        margin: 0 0 rem(10px) 0;
        display: block;
    }

    &:nth-of-type(1) {
        @include media-breakpoint-up(lg) {
            margin-left: rem(-16px);
        }

        /*&.is--active {
      margin-left: 0;
    }*/
    }

    &:nth-last-of-type(1) {
        margin-right: 0;
    }

    &::before {
        display: none;
    }

    > a {
        font-weight: 500;
        font-size: rem(19px);
        color: var(--color-black);
        @include underline;
        text-underline-offset: 2px;
        padding: rem(10px) rem(16px);
        display: inline-block;
        position: relative;
        transition: color 0.2s, background-color 0.2s;

        @include media-breakpoint-down(xxl) {
            font-size: rem(17px);
        }

        @include media-breakpoint-down(xl) {
            padding-left: rem(13px);
            padding-right: rem(13px);
        }

        @include media-breakpoint-down(lg) {
            font-size: rem(19px);
            padding-left: 0;
        }

        &:hover,
        &:focus {
            color: var(--color-primary);
            text-decoration: none;
        }

        &:active {
            background-color: rgba($primary-color, 0.04);
        }
    }

    &.item--out > a {
        color: var(--color-primary);

        svg {
            margin: -1px 0 0 4px;
            width: 15px;
            min-width: 15px;
            height: 15px;
        }
    }

    &.is--active > a {
        text-decoration: none;
        color: var(--color-primary);

        @include media-breakpoint-down(lg) {
            color: var(--color-white);
            background-color: var(--color-black);
            text-decoration: none;
            padding-left: rem(13px);
        }
    }

    a.dropdown-toggle {
        @include media-breakpoint-up(lg) {
            padding-right: rem(34px);
        }

        &::after {
            display: none;
        }

        &.show {
            color: var(--color-primary);

            .dropdown__arrow {
                transform: rotate(-180deg);
            }
        }

        .dropdown__arrow {
            position: absolute;
            top: calc(50% - 6px);
            right: 12px;
            transition: transform 0.2s;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }
}

.item__badge {
    @extend .badge__count;
    left: calc(100% - 10px) !important;

    @include media-breakpoint-up(lg) {
        left: calc(100% - 34px) !important;
    }
}



// navigation responsive
.navigation__toggle {
    background: var(--color-black);
    border: none;
    border-radius: 50%;
    display: none;
    margin: 0 0 0 rem(16px);
    padding: 0;
    width: 42px;
    height: 42px;
    position: relative;
    z-index: 1000;
    cursor: pointer;
    transition: 0.2s;

    @include media-breakpoint-down(lg) {
        display: block;
    }

    @media (max-width: 400px) {
        margin-left: rem(12px);
    }

    &:hover,
    &:focus {
        background-color: var(--color-grey-700);
    }

    &:active {
        background-color: var(--color-grey-500);
    }

    &.is--active {
        background-color: var(--color-white);
        border: 1px solid var(--color-black);

        .bar__line {
            background-color: var(--color-black);
            height: 1px;
            left: 11px;

            &.bar--t {
                transform: rotate(45deg);
                top: 20px;
            }

            &.bar--m {
                opacity: 0;
            }

            &.bar--b {
                transform: rotate(-45deg);
                top: 20px;
            }
        }
    }
}

.bar__line {
    background-color: var(--color-white);
    border-radius: 4px;
    display: block;
    width: 18px;
    height: 1px;
    margin: 0;
    position: absolute;
    left: 12px;
    transition: transform 0.2s, opacity 0.2s;

    &.bar--t {
        top: 14px;
    }

    &.bar--m {
        top: 20px;
    }

    &.bar--b {
        top: 26px;
    }
}



// show responsive/sub navigation
body::after {
    content: "";
    transition: opacity 1s;
    opacity: 0;
}

body.show-resp-nav {
    height: 100vh;
    overflow: hidden;

    &::after {
        content: "";
        background-color: var(--color-black);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.65;
        z-index: 910;
    }

    .section,
    .header,
    .footer,
    .newsletter {
        filter: blur(6px);
        //filter: blur(5px) grayscale(1);
    }

    .navigation {
        right: 0;
    }
}

body.show-nav {
    &::after {
        content: "";
        background-color: var(--color-black);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.4;
        z-index: 900;
    }

    /*.section, .footer, .newsletter {
    filter: blur(6px);
  }*/
}



// navigation dropdown
.navigation__dropdown {
    border-radius: 0;
    background: none;
    box-shadow: 0 20px 20px 0 rgba($black-color, 0.1);
    display: block;
    width: 100%;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    left: 0;
    opacity: 0;
    transition: all 0.15s;

    @include media-breakpoint-down(lg) {
        display: none !important;
    }

    &.show {
        padding: 0;
        max-height: 600px;
        opacity: 1;
    }
}

.navigation__dropdown-inner {
    //background-color: var(--color-white);
    background-color: var(--color-grey-110);
    padding: ($spacer * 2.25) 0 ($spacer * 4) 0;
    position: relative;
    overflow: hidden;

    .heading-row,
    .wide-carousel {
        position: relative;
        z-index: 10;
    }

    .wide-carousel .card {
        margin-bottom: 0;
    }
}

.navigation__bubble {
    color: var(--black-white);
    position: absolute;
    bottom: -70px;
    right: 50px;
    opacity: 0.09;
    z-index: 0;
}

.col--subnavigation {
    position: relative;

    &::after {
        content: "";
        background-color: var(--border-color);
        width: 1px;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: $spacer * 2.5;

        @include media-breakpoint-down(xxl) {
            right: $spacer * 1.25;
        }

        @include media-breakpoint-down(xl) {
            right: 0;
        }
    }

    h3 {
        margin-bottom: $spacer * 2;
    }
}

.subnavigation__list {
    margin-bottom: $spacer * 2.5;
}

.subnavigation__item {
    &::before {
        top: 2px;
    }

    a {
        color: var(--color-black);
    }
}