// timelines
.timeline {
    position: relative;
}

.timeline__item {
    position: relative;

    &::after {
        content: "";
        background-color: rgba($black-color, 0.1);
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        left: 39px;
        top: 0;
        z-index: 0;

        @include media-breakpoint-down(sm) {
            left: 30px;
        }
    }

    &:nth-last-of-type(1) {
        &::after {
            display: none;
        }

        .timeline__container {
            padding-bottom: 0;
        }
    }
}

.timeline__container {
    background-color: var(--color-grey-100);
    padding: ($spacer * 0.5) 0 ($spacer * 3.5) ($spacer * 7.5);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;

    @include media-breakpoint-down(xl) {
        padding-bottom: $spacer * 2.25;
    }

    @include media-breakpoint-down(sm) {
        padding-top: $spacer * 0.375;
        padding-left: $spacer * 5.5;
    }
}

.timeline__year {
    text-align: center;
    font-size: rem(20px);
    font-weight: 700;
    line-height: 1;
    color: var(--color-white);
    background-color: var(--color-black);
    padding-top: $spacer * 0.4375;
    width: 78px;
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @include boxshadow;

    &.year--highlight {
        background-color: var(--color-primary);

        &::after {
            border-color: transparent var(--color-primary) transparent transparent;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: rem(16px);
        padding-top: $spacer * 0.375;
        width: 60px;
        height: 28px;
    }

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 4px 0;
        border-color: transparent var(--color-black) transparent transparent;
        position: absolute;
        right: 0;
        bottom: -4px;
        z-index: 3;
    }

    /*&::after {
    content: "";
    background-color: var(--color-primary);
    border-radius: 50%;
    border: 3px solid var(--color-grey-100);
    //box-shadow: 0 2px 4px rgba($black-color, 0.1);
    display: block;
    width: 13px;
    height: 13px;
    position: absolute;
    left: -5px;
    bottom: -5px;
    z-index: 3;
  }*/
}

.timeline__heading {
    @extend .h5;
    margin-bottom: $spacer * 0.675;
}

.timeline__text {
    color: var(--color-grey-400);

    p:nth-last-of-type(1) {
        margin-bottom: 0;
    }
}