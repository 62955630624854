.list--ladder {
    background-color: var(--color-grey-100);
    margin: 0 0 ($spacer * 1.75) 0;
    padding: $spacer * 1.5;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    counter-reset: ol_counter;

    li {
        line-height: 1.3125;
        margin: 0 0 ($spacer * 0.875) 0;
        padding: 0;
        display: inline-flex;
        list-style: none;
        counter-increment: ol_counter;
        position: relative;

        &::before {
            content: counter(ol_counter);
            border-radius: 50%;
            font-weight: 500;
            font-size: rem(16px);
            color: var(--color-white);
            background-color: var(--color-primary);
            box-shadow: 0 5px 10px -6px rgba($black-color, 0.75);
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -2px;
            left: -36px;
        }

        &:nth-last-of-type(1) {
            margin-bottom: 0;
        }

        a {
            color: var(--color-black);
            display: inline-flex;

            .icon--left {
                vertical-align: top;
                pointer-events: visible;
            }
        }
    }
}