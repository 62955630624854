// form
fieldset {
    border: none;
    margin: 0;
    padding: 0;
}



// form line
.form__line {
    margin: 0 0 ($spacer * 1.675) 0;
}



// label
.form__label {
    font-size: rem(15px);
    font-family: var(--body-font-family);
    font-weight: var(--body-font-weight);
    color: var(--color-black);
    display: block;
    margin: 0 0 ($spacer * 0.375) 0;
}

.label__optional {
    color: var(--color-grey-300);
    font-style: italic;
}



// input
.form__control {
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    font-weight: var(--body-font-weight);
    color: var(--color-black);
    background-color: var(--color-white);
    border: 2px solid var(--border-color);
    //border-radius: 6px;
    margin: 0;
    padding: 0.25rem 1.25rem;
    width: 100%;
    height: 44px;
    box-shadow: none;
    display: block;
    transition: border-color 0.2s, box-shadow 0.2s;

    &:focus {
        border-color: var(--color-grey-700);
        outline: none;
    }

    &:disabled,
    &[readonly] {
        border-color: var(--color-grey-200);
        background-color: var(--color-grey-200);
        color: var(--color-grey-400);
        cursor: not-allowed;
    }

    &.control--noborder {
        border-color: var(--color-white);

        &:focus {
            border-color: var(--color-grey-700);
        }
    }

    &[aria-invalid='false'] {
        border-color: var(--color-success);
    }

    &[aria-invalid='true'] {
        border-color: var(--color-danger);
    }
}

textarea.form__control {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    height: auto;
}



// checkbox, radio
.form__check {
    display: block;
    min-height: 20px;
    padding-left: $spacer * 2.25;
    margin-bottom: $spacer * 0.5;
}

.check__input {
    width: 20px;
    height: 20px;
    margin-top: $spacer * 0.0625;
    margin-left: $spacer * -2.25;
    vertical-align: top;
    border: 2px solid var(--border-color);
    background-color: var(--color-white);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
    float: left;
    cursor: pointer;

    &[type=radio] {
        border-radius: 50%;
    }

    &:checked[type=radio] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
    }

    &:checked[type=checkbox] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    }

    &:checked {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
    }

    &:focus {
        border-color: var(--color-grey-700);
        outline: none;
    }
}

.check__label {
    display: inline-block;
    cursor: pointer;
}



// file
.form__file {
    @extend .form__control;
    position: relative;
    cursor: pointer;
    margin-bottom: $spacer * 0.5;

    input {
        display: none;
    }

    &:hover {
        border-color: var(--color-grey-700);

        .form__file-icon {
            color: var(--color-white);
            background-color: var(--color-grey-700);
        }
    }
}

.form__file-text {
    padding-top: $spacer * 0.25;
    display: inline-block;
}

.form__file-icon {
    color: var(--color-black);
    background-color: var(--border-color);
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transition: background-color 0.2s;

    svg {
        margin: 0;
        display: block;
        width: 20px;
        height: 20px;
    }
}



// form feedback
.form__feedback {
    font-size: rem(12px);
    line-height: 0.9375;
    padding-top: $spacer * 0.6;
    display: none;
}

[aria-invalid='false'] + .form__feedback {
    color: var(--color-success);
    display: block;
}

[aria-invalid='true'] + .form__feedback {
    color: var(--color-danger);
    display: block;
}



// radio, checkbox
.custom-control {
    margin-bottom: $spacer;
    padding-left: 37px;
    min-height: 0;
}

.custom-control-label {
    color: var(--color-black);
    cursor: pointer;
    padding-top: $spacer * 0.275;

    &::before,
    &::after {
        top: 0;
        left: -37px;
    }

    &::before {
        background-color: var(--color-white);
        border: none;
        width: 20px;
        height: 20px;
    }
}

.custom-radio {
    .custom-control-input:active ~ .custom-control-label::before {
        background: lighten($primary-color, 10%);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background: var(--color-white);
        border: none;
        box-shadow: none;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background: var(--color-primary);
        border-radius: 50%;
        border: none;
        box-shadow: none;
        width: 10px;
        height: 10px;
        top: 5px;
        left: -32px;
    }
}

.custom-checkbox {
    .custom-control-label {

        &::after,
        &::before {
            border-radius: 0;
        }
    }

    .custom-control-input:active ~ .custom-control-label::before {
        background: lighten($primary-color, 10%);
        border: none;
        box-shadow: none;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        border: none;
        box-shadow: none;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background: var(--color-white);
        border: none;
        box-shadow: none;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 8 8'%3e%3cpath fill='%23c2a059' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") no-repeat 4px 4px;
    }
}



// help tooltip
.help-button,
.help-button[type=button]:not(:disabled) {
    border: none;
    background: none;
    color: var(--color-info);
    vertical-align: top;
    margin: 0 ($spacer * 0.3125) -2px;
    padding: 0;
    display: inline-block;
    cursor: help;

    &:hover,
    &:focus {
        color: var(--color-black);
        outline: none;
    }
}