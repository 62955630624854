.row--pages {
    margin-top: $spacer;

    @include media-breakpoint-down(md) {
        text-align: center;
        margin-top: $spacer * 0.5;

        .col--pages-hide {
            display: none;
        }

        .col--pages-num {
            margin-top: rem(16px);
        }
    }
}

.pages__list {
    margin: 0;
    padding: 0;
}

.pages__item {
    margin: 0;
    padding: 0;
    display: inline-block;

    &::before {
        display: none;
    }
}

.pages__link {
    color: var(--color-black);
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 0 ($spacer * 0.6125);
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.2s, border-color 0.2s;

    &:hover,
    &:focus {
        color: var(--color-primary);
    }

    &.is--active {
        border-color: var(--color-black);
        color: var(--color-black);
        margin: 0 ($spacer * 0.375);
        width: 42px;

        &:hover,
        &:focus {
            border-color: var(--color-grey-500);
            color: var(--color-grey-500);
        }
    }
}