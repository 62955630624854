// collapse
.link--collapse {
    .icon {
        transform: rotate(180deg);
        transition: transform 0.2s;
    }

    &.collapsed .icon {
        transform: rotate(0);
    }
}