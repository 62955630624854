// buttons
.button {
    font-size: rem(17px);
    font-weight: 700;
    font-family: var(--body-font-family);
    line-height: 1;
    color: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 70px;
    background-color: var(--color-primary);
    text-decoration: none;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: ($spacer * 0.6875) ($spacer * 1.375);
    position: relative;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s;

    &:hover,
    &:focus {
        background-color: var(--color-primary-light);
        border-color: var(--color-primary-light);
        color: var(--color-white);
        outline: none;
    }

    &:active {
        background-color: var(--color-primary-dark);
        border-color: var(--color-primary-dark);
        color: var(--color-white);
    }

    &.button--noradius {
        border-radius: 0;
    }

    &.button--input {
        height: 44px;
    }


    // outline button
    &.button--outline {
        background-color: transparent;
        border-color: var(--color-black);
        color: var(--color-black);
        font-weight: 500;

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: var(--color-grey-500);
            color: var(--color-grey-700);
        }

        &:active {
            background-color: transparent;
            border-color: var(--color-grey-700);
            color: var(--color-grey-700);
        }


        &.button--grey {
            background-color: transparent;
            border-color: var(--color-grey-200);
            color: var(--color-grey-700);

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
                border-color: var(--color-black);
                color: var(--color-black);
            }
        }

        &.is--active {
            background-color: var(--color-black);
            border-color: var(--color-black);
            color: var(--color-white);

            &:hover,
            &:focus,
            &:active {
                background-color: var(--color-grey-800);
                border-color: var(--color-grey-800);
                color: var(--color-white);
            }
        }
    }


    // white button
    &.button--white {
        background-color: var(--color-white);
        border-color: var(--color-white);
        color: var(--color-black);

        &:hover,
        &:focus {
            background-color: var(--color-white);
            border-color: var(--color-white);
            color: var(--color-grey-500);
        }

        &:active {
            background-color: var(--color-grey-100);
            border-color: var(--color-grey-100);
            color: var(--color-grey-700);
        }
    }


    // dark button
    &.button--dark {
        background-color: var(--color-black);
        border-color: var(--color-black);
        color: var(--color-white);

        &:hover,
        &:focus {
            background-color: var(--color-grey-800);
            border-color: var(--color-grey-800);
            color: var(--color-grey-100);
        }

        &:active {
            background-color: var(--color-grey-500);
            border-color: var(--color-grey-500);
            color: var(--color-white);
        }
    }


    // success button
    &.button--success {
        background-color: var(--color-success);
        border-color: var(--color-success);

        &:hover,
        &:focus {
            background-color: var(--color-success-light);
            border-color: var(--color-success-light);
        }

        &:active {
            background-color: var(--color-success-dark);
            border-color: var(--color-success-dark);
        }
    }


    // disabled button
    &.button--disabled,
    &[disabled],
    &.button--disabled:hover,
    &[disabled]:hover,
    &.button--disabled:focus,
    &[disabled]:focus {
        background-color: var(--color-grey-300);
        border-color: var(--color-grey-300);
        color: var(--color-grey-500);
        cursor: not-allowed;
    }


    // small button
    &.button--small {
        font-weight: 400;
        font-size: var(--body-font-size);
        padding: ($spacer * 0.4375) ($spacer * 0.875);
    }
}



// link
.link {
    background: none;
    border: none;
    color: var(--color-primary);
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: color 0.2s;

    &:hover,
    &:focus {
        color: var(--color-primary);

        span {
            text-decoration: none;
        }
    }

    &:active {
        color: var(--color-primary-dark);
    }

    span {
        @include underline;
    }


    &.link--dark {
        color: var(--color-black);

        &:hover,
        &:focus,
        &:active {
            color: var(--color-black);
        }
    }

    &.link--grey {
        color: var(--color-grey-500);

        &:hover,
        &:focus,
        &:active {
            color: var(--color-grey-500);
        }
    }

    &.link--white {
        color: var(--color-white);

        &:hover,
        &:focus,
        &:active {
            color: var(--color-primary);
        }
    }
}

button.link {
    padding: 0;
    cursor: pointer;
}



// icons in buttons/links
.icon {
    line-height: 1;
    margin: -1px 0;
    width: 17px;
    min-width: 17px;
    height: 17px;
    display: block;
}

.icon--15px {
    width: 15px;
    min-width: 15px;
    height: 15px;
}

.icon--21px {
    width: 21px;
    min-width: 21px;
    height: 21px;
}

.icon--right {
    margin-left: $spacer * 0.5;
}

.icon--left {
    margin-right: $spacer * 0.5;
}

.icon--inline {
    display: inline-block;
}